import React, { useEffect, useState, memo } from "react";
import { TextBox } from "assets/Styles/SText";

export const Timer = memo(({ reTimer, setReTimer, alert }) => {
  const MINUTES_IN_MS = 3 * 60 * 1000;
  const INTERVAL = 1000;
  const [timeLeft, setTimeLeft] = useState(MINUTES_IN_MS);

  let minutes = String(Math.floor((timeLeft / (1000 * 60)) % 60)).padStart(2, "0");
  let second = String(Math.floor((timeLeft / 1000) % 60)).padStart(2, "0");
  useEffect(() => {
    if (timeLeft === 0) {
      if (timeLeft === 0 && alert) {
        alert();
        setReTimer(false);
        return;
      }
      setReTimer(false);
      return;
    }

    const timer = setInterval(() => {
      setReTimer(false);
      setTimeLeft((prevTime) => prevTime - INTERVAL);
    }, INTERVAL);

    return () => {
      clearInterval(timer);
    };
  }, [timeLeft]);

  useEffect(() => {
    if (reTimer === true) {
      setTimeLeft(MINUTES_IN_MS);
    }
  }, [reTimer]);

  return (
    <>
      <TextBox $class={["caption", "purple"]} $white="nowrap">
        {minutes}:{second}
      </TextBox>
    </>
  );
});
