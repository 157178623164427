import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useMedia } from "util/useMedia";
import { GameInfoBox } from "assets/Styles/SLayout";
import { useCommonState } from "context/useContext";
import { CutWord } from "assets/Styles/custom/CustomText";
import { Column, ContentBox, Grid, Row, Thumbnail } from "assets/Styles/custom/CustomLayout";

import { SelectForm, SelectFormMulti } from "data/useForm";
import useGetEnum from "enum/useGetEnum";

import api from "interceptor/api";
import useGetData from "data/useGetData";
import useFormData from "data/useFormData";

import SearchCompo from "components/hooks/SearchCompo";
import NoSearch from "components/common/NoSearch";
import MorePaging from "components/hooks/MorePaging";
import { SectionTitle } from "components/common/useCommonStyle";
import NoListLayout from "components/common/NoListLayout";
import { t } from "i18next";

const TableGame = () => {
  const enums = useGetEnum();
  const pathname = useLocation();
  const { menu_name, menu_code } = useParams();
  const { setLoading } = useCommonState();
  const { isMaxTablet, isMaxMiddleM } = useMedia();
  const [gameOptions, setGameOptions] = useState([]);

  const getGameList = () => {
    setLoading(true);

    const apiUrl = !getValue.formValue.value ? "/v1/game/side-menu/game" : "/v1/game/search/title";

    api
      .get(apiUrl, {
        params: {
          menu_code: menu_code,
          ...getValue?.searchValue?.value,
          ...useGame.extendData.pagingData,
        },
      })
      .then((res) => {
        useGame.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useGame.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useGame.setError("");
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        useGame.setData([]);
        useGame.setError(true);
      });
  };

  const gameCompanyList = () => {
    setLoading(true);

    const apiUrl = "/v1/game/provider-list";
    const params = {
      ...(getValue?.searchValue?.value ? { value: getValue.searchValue.value } : {}),
      menu_code: menu_code,
    };

    api
      .get(apiUrl, { params })
      .then((res) => {
        setLoading(false);
        setGameOptions(res.data.content);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const useGame = useGetData({
    pageSize: "32",
    select: "order",
    selectData: "latest",
    firstInit: true,
    multiSelect: "provider",
    multiData: [],
  });

  const getValue = useFormData(
    {
      value: "",
    },
    getGameList,
    useGame
  );

  useEffect(() => {
    gameCompanyList();
  }, [getValue.searchValue]);

  useEffect(() => {
    getGameList();
  }, [pathname]);

  if (!useGame.data) return;

  return (
    <Column $gap={isMaxMiddleM ? "20px" : "28px"}>
      <SectionTitle name={menu_name} />

      <Column $gap="12px">
        <SearchCompo
          name="value"
          apiFunc={getGameList}
          page="32"
          getData={useGame}
          placeholder={t("placeholder.search")}
          {...getValue}
        />
      </Column>

      <Column $gap={isMaxMiddleM ? "16px" : "20px"}>
        <Row $gap="12px" $width={isMaxMiddleM && "100%"}>
          <SelectFormMulti name={"provider"} options={gameOptions} placeholder={t("placeholder.vendor")} {...useGame} />
          <SelectForm
            name={"order"}
            conWidth={isMaxMiddleM && "100%"}
            options={enums.sortOrder}
            placeholder={t("state.popular")}
            {...useGame}
          />
        </Row>
        {useGame?.data?.length > 0 && (
          <>
            <Grid>
              {useGame?.data?.map(({ title, thumbnail, id, vendor }) => (
                <ContentBox
                  as="a"
                  key={vendor + id}
                  $direct="column"
                  $align="center"
                  $backColor="var(--c-gray-700)"
                  $radius={isMaxMiddleM ? "8px" : "12px"}
                  $cursor="pointer"
                  href={`/game_zone/${vendor}/${id}`}
                >
                  <Thumbnail
                    $width="100%"
                    src={thumbnail}
                    $height={isMaxMiddleM ? "104px" : "179px"}
                    $radius={isMaxMiddleM ? "8px 8px 0 0" : "12px 12px 0 0"}
                    alt={vendor + id}
                  />
                  <GameInfoBox $pad={isMaxTablet && "8px 12px"}>
                    <CutWord $maxWidth="100%">{title}</CutWord>
                  </GameInfoBox>
                </ContentBox>
              ))}
            </Grid>
          </>
        )}
      </Column>
      {useGame?.data?.length > 0 && (
        <MorePaging num={useGame?.data?.length} totalNum={useGame?.pagingInfo?.offset} {...useGame} />
      )}
      {getValue.searchValue.value && useGame?.data?.length === 0 ? (
        <NoSearch />
      ) : (
        useGame?.data?.length === 0 && <NoListLayout />
      )}
    </Column>
  );
};

export default TableGame;
