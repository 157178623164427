import React, { useEffect, useState } from "react";

import api from "interceptor/api";
import Login from "../popup/Login";

import { TextBox } from "assets/Styles/SText";
import { Row } from "assets/Styles/custom/CustomLayout";

import { useLocation, useNavigate } from "react-router-dom";
import { useForYouMenu, useMyPageMenu, useSupport } from "enum/menu/useMenu";

import { Hamburger, HeaderCloseIcon, Logo, MenuArrow } from "assets/components/IconComponent";

import {
  ArrowBox,
  AsideLayOut,
  CloseIcon,
  GroupBox,
  GroupList,
  GroupTitle,
  GroupUL,
  MenuColumn,
  MenuTitle,
  MenuWrap,
  NavMenu,
} from "assets/Styles/componentstyle/AsideStyle";

import { useMedia } from "util/useMedia";
import { useCommonState } from "context/useContext";
import { useAlert, usePopup } from "context/useWindow";
import { getIconComponent } from "util/IconMatching";
import { t } from "i18next";

const Aside = ({ menuToggle, menuSlide, setMenuSlide }) => {
  const navigate = useNavigate();

  const { openAlert } = useAlert();
  const { openPopup } = usePopup();
  const { pathname } = useLocation();
  const { isMaxMiddleM } = useMedia();
  const { isLogin, userState } = useCommonState();

  const [gameTitle, setGameTitle] = useState("");
  const [gameMenus, setGameMenus] = useState([]);
  const [dropdownState, setDropdownState] = useState({});

  const updateTableSubMenu = () => {
    const url = "/v1/game/side-menu";
    api
      .get(url)
      .then((res) => {
        const menus = res.data.content;
        if (!menus || menus.length === 0) {
          throw new Error("Menu data is empty or undefined.");
        }
        const updatedGameMenus = {};

        menus.forEach((menu) => {
          if (!menu.subMenus || menu.subMenus.length === 0) {
            return;
          }
          setGameTitle(menu.name);

          menu.subMenus.forEach((subMenu) => {
            const groupKey = `${subMenu.name}`;

            const updatedTableGroup = {
              groupTitle: subMenu.name,
              groupRoute: `/${encodeURIComponent(subMenu.name)}/${subMenu.menuCode}`,
              enum: subMenu.name,
              key: groupKey,
              IconComponent: getIconComponent(subMenu.icon),
              iconProps: {
                width: "22",
                height: "22",
                fill: "var(--c-gray-300)",
              },
              GROUP: {},
            };

            if (subMenu.subMenus && subMenu.subMenus.length > 0) {
              subMenu.subMenus.forEach((item) => {
                updatedTableGroup.GROUP[item.name] = {
                  title: item.name,
                  enum: `ITEM_${item.menuCode}`,
                  route: `/${encodeURIComponent(item.name)}/${item.menuCode}`,
                  key: `ITEM_${item.menuCode}`,
                  sideIconComponent: getIconComponent(item.icon),
                  sideIconProps: {
                    width: "22",
                    height: "22",
                    fill: "var(--c-gray-300)",
                  },
                };
              });
            }

            updatedGameMenus[groupKey] = updatedTableGroup;
          });
        });

        setGameMenus({
          ...updatedGameMenus,
        });
      })
      .catch((err) => {
        console.error("Error fetching menu:", err);
      });
  };
  useEffect(() => {
    updateTableSubMenu();
  }, []);

  useEffect(() => {
    const updateDropdownState = () => {
      const updatedState = {};
      menuLists.forEach((main) => {
        Object.values(main.enums).forEach((group) => {
          const groupRoute = group.groupRoute;
          const hasMatchingRoute =
            (group.GROUP && Object.values(group.GROUP).some((menu) => pathname.includes(menu.route))) ||
            pathname.includes(groupRoute);
          updatedState[groupRoute] = hasMatchingRoute;
        });
      });
      setDropdownState(updatedState);
    };
    updateDropdownState();
  }, [pathname, gameMenus]);

  const dropDown = (groupRoute) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [groupRoute]: !prevState[groupRoute],
    }));
  };

  const supportMenu = useSupport();
  const forYouMenu = useForYouMenu();
  const myPageMenu = useMyPageMenu();

  const menuLists = [
    { title: "MY PAGE", enums: myPageMenu },
    { title: "FOR YOU", enums: forYouMenu },
    ...(gameMenus ? [{ title: gameTitle, enums: gameMenus }] : []),
    { title: "SUPPORT", enums: supportMenu },
  ];

  const showPopup = () => {
    openPopup({
      content: Login,
      title: t("sectionTitle.login"),
    });
  };

  const showAlert = () => {
    openAlert({
      title: t("alert.join_wait"),
      message: t("alert.join_wait_message"),
    });
  };

  if (!gameMenus) return null;

  return (
    <>
      <AsideLayOut className={menuSlide && "active"}>
        <NavMenu $gap="25px" $align="center">
          {!isMaxMiddleM && <Hamburger className={menuSlide && "active"} onClick={menuToggle} />}
          {menuSlide && (
            <CloseIcon onClick={menuToggle}>
              <HeaderCloseIcon />
            </CloseIcon>
          )}
          {!menuSlide && !isMaxMiddleM && <Logo onClick={() => navigate("/")} />}
        </NavMenu>
        <MenuWrap className={menuSlide && "active"}>
          {menuLists
            .filter(({ enums }) => enums && Object.keys(enums).length > 0)
            .map(({ title, enums }) => (
              <MenuColumn key={title} $gap="8px" $align={menuSlide && "center"} $width="100%">
                <MenuTitle>{title}</MenuTitle>
                {Object.values(enums).map(
                  ({ key, GROUP, iconProps, notAuth, groupTitle, groupRoute, loginDisable, IconComponent }) => {
                    const isDropdown = dropdownState[groupRoute];
                    const isActive =
                      (GROUP && Object.values(GROUP).some(({ route }) => pathname === `${route}` || "")) ||
                      pathname.includes(groupRoute);
                    return (
                      <React.Fragment key={key}>
                        <GroupBox
                          as="button"
                          className={`${menuSlide ? "active" : ""} ${isDropdown ? "menu-slide" : ""}`}
                          disabled={!isLogin && loginDisable ? "not-login" : ""}
                        >
                          <Row
                            $gap="12px"
                            $align="center"
                            $width="100%"
                            onClick={() => {
                              if (!isLogin) {
                                showPopup();
                              } else if (userState === "W" && !notAuth) {
                                showAlert();
                              } else {
                                if (isMaxMiddleM) {
                                  navigate(groupRoute);
                                  setMenuSlide(!menuSlide);
                                }
                                navigate(groupRoute);
                              }
                            }}
                          >
                            {IconComponent && (
                              <Row $width="22px" $height="22px">
                                {React.cloneElement(IconComponent, {
                                  ...iconProps,
                                  fill: isActive ? "var(--c-purple)" : iconProps.fill,
                                  width: "22",
                                  height: "22",
                                })}
                              </Row>
                            )}
                            <GroupTitle $class="text" className={`group-title ${menuSlide ? "active" : ""}`}>
                              {groupTitle}
                            </GroupTitle>
                          </Row>
                          {GROUP && Object.values(GROUP).length !== 0 && (
                            <ArrowBox
                              className={`menu-arrow ${menuSlide ? "active" : ""}`}
                              onClick={() => {
                                dropDown(groupRoute);
                              }}
                            >
                              <MenuArrow />
                            </ArrowBox>
                          )}
                        </GroupBox>
                        {isDropdown && GROUP && Object.values(GROUP).length !== 0 && (
                          <GroupUL className={`menu-group ${menuSlide ? "active" : ""}`}>
                            {Object.values(GROUP).map(({ key, title, route, sideIconProps, sideIconComponent }) => {
                              const sideActive = pathname.includes(route);
                              return (
                                <GroupList
                                  key={key}
                                  className={pathname.includes(`/${route}`) ? "active" : ""}
                                  onClick={() => {
                                    if (!isLogin) {
                                      showPopup();
                                    } else if (userState === "W") {
                                      showAlert();
                                    } else {
                                      if (isMaxMiddleM) {
                                        setMenuSlide(!menuSlide);
                                        navigate(`${route}`);
                                      } else {
                                        navigate(`${route}`);
                                      }
                                    }
                                  }}
                                >
                                  {sideIconComponent &&
                                    React.cloneElement(sideIconComponent, {
                                      ...sideIconProps,
                                      fill: sideActive ? "var(--c-purple)" : sideIconProps.fill,
                                      width: "22",
                                      height: "22",
                                    })}
                                  <TextBox $class="text">{title}</TextBox>
                                </GroupList>
                              );
                            })}
                          </GroupUL>
                        )}
                      </React.Fragment>
                    );
                  }
                )}
              </MenuColumn>
            ))}
        </MenuWrap>
      </AsideLayOut>
    </>
  );
};

export default Aside;
