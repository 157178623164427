import React from "react";

import { TabsForm } from "data/useForm";
import { useMedia } from "util/useMedia";
import useGetEnum from "enum/useGetEnum";
import { dateTimeFormat } from "util/DateUtil";
import { useCommonState } from "context/useContext";
import { CutWord } from "assets/Styles/custom/CustomText";

import { TextBox } from "assets/Styles/SText";
import { FlexBox, GrayCon, MyPageLayout, NotMenuLayout } from "assets/Styles/SLayout";
import { ContentSwap, P_GradientBtn } from "assets/Styles/SButton";
import { Column, Linked, Row } from "assets/Styles/custom/CustomLayout";

import api from "interceptor/api";
import useGetData from "data/useGetData";
import Pagination from "components/hooks/Pagination";
import NoListLayout from "components/common/NoListLayout";
import { SectionTitle } from "components/common/useCommonStyle";
import { t } from "i18next";
import MyMenu from "components/my/layout/MyMenu";

const CustomerQna = () => {
  const enums = useGetEnum();
  const { isMiddleD } = useMedia();
  const { setLoading } = useCommonState();

  const getQnaInfo = () => {
    setLoading(true);

    const apiUrl = "/v1/user/qna";

    api
      .get(apiUrl, {
        params: {
          ...getQna.extendData.pagingData,
        },
      })
      .then((res) => {
        setLoading(false);
        getQna.setData(res.data.content);

        if (res.data.pageHelperDto) {
          getQna.setPagingInfo(res.data.pageHelperDto);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        getQna.setData([]);
      });
  };

  const getQna = useGetData({
    firstInit: true,
    api: getQnaInfo,
    pageSize: "10",
    typeData: "",
  });

  const getData = getQna.data;

  if (!getData) return;

  return (
    <FlexBox $gap={isMiddleD && "28px"} $direct={isMiddleD ? "row" : "column"}>
      <MyMenu />
      <MyPageLayout>
        <Column $gap="20px">
          <Row $pad="6px" $radius="30px" $backColor="var(--c-gray-900)">
            <ContentSwap href="/customer">{t("sectionTitle.faq")}</ContentSwap>
            <ContentSwap className="active">{t("sectionTitle.one_to_one")}</ContentSwap>
          </Row>

          <P_GradientBtn as="a" href="/customer/qna/contact" $pad="12px 0" $radius="12px" $tAlign="center">
            {t("button.one_to_one_inquiry")}
          </P_GradientBtn>

          <Column $gap="12px">
            <TabsForm
              rowGap={"8px"}
              radius={"50px"}
              pad="10px 16px"
              activeBg={"var(--c-gray-700)"}
              backColor={"var(--c-gray-900)"}
              activeTc={["var(--c-white)", "subB"]}
              textClass={["var(--c-gray-400)", "subB"]}
              options={enums.qnaState}
              {...getQna}
            />
            {getData.length !== 0 &&
              getData.map(({ no, question_title, state, create_at }) => {
                let stateText = "";
                let stateStyle = [];

                switch (state) {
                  case "ANSWERED":
                    stateText = t("state.answer_complete");
                    stateStyle = ["subB", "purple"];
                    break;
                  case "PROCESSING":
                    stateText = t("state.processing");
                    stateStyle = ["subB", "gray200"];
                    break;

                  default:
                    stateText = "";
                    stateStyle = [];
                }

                return (
                  <Linked key={no} to={`/customer/qna/answer/${no}`}>
                    <GrayCon className="ho-ac" $radius="12px" $pad="16px 20px">
                      <CutWord as="p" $dis="block" $class={["title", "white"]}>
                        {question_title}
                      </CutWord>
                      <Row $gap="8px" $pad="4px 0 0">
                        <TextBox $class={stateStyle}>{stateText}</TextBox>
                        <TextBox $class="gray300">{dateTimeFormat(create_at)}</TextBox>
                      </Row>
                    </GrayCon>
                  </Linked>
                );
              })}
            {getData.length === 0 && <NoListLayout />}
          </Column>

          {getData.length !== 0 && <Pagination {...getQna} />}
        </Column>
      </MyPageLayout>
    </FlexBox>
  );
};

export default CustomerQna;
