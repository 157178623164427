import React from "react";
import { t } from "i18next";
import styled, { css } from "styled-components";

import api from "interceptor/api";
import useGetData from "data/useGetData";

import useGetEnum from "enum/useGetEnum";

import { useMedia } from "util/useMedia";
import { SelectForm } from "data/useForm";
import { dateCommaFormat, dateFormat } from "util/DateUtil";
import { usePopup } from "context/useWindow";
import { useCommonState } from "context/useContext";

import { TextBox } from "assets/Styles/SText";
import { Empty, FlexBox, MyPageLayout } from "assets/Styles/SLayout";
import { MoneyIcon, PointIcon } from "assets/components/IconComponent";
import { Table, TableDeps, TableHeader, TableRow } from "assets/Styles/STable";
import { BorderBtn, P_GradientBox, P_GradientBtn } from "assets/Styles/SButton";
import { Column, ContentBox, Row, ScrollBox, Thumbnail } from "assets/Styles/custom/CustomLayout";

import MyMenu from "../layout/MyMenu";
import useFormData from "data/useFormData";
import HistoryDetail from "./HistoryDetail";
import Pagination from "components/hooks/Pagination";
import SearchCompo from "components/hooks/SearchCompo";
import NoListLayout from "components/common/NoListLayout";
import CalendarFilter from "components/common/CalendarFilter";
import { postRequest } from "apis/postRequest";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;

  @media (max-width: 1750px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    gap: 12px;
  }
`;

const MyRecommend = () => {
  const enums = useGetEnum();
  const { openPopup } = usePopup();
  const { setLoading } = useCommonState();
  const { isMiddleD, isMaxMiddleM, isScroll } = useMedia();

  const getRecommend = () => {
    setLoading(true);

    const apiUrl = "/v1/refer/info";

    api
      .get(apiUrl)
      .then((res) => {
        recommendInfo.setData(res.data.content);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getLevels = () => {
    setLoading(true);

    const apiUrl = "/v1/refer/level";

    api
      .get(apiUrl)
      .then((res) => {
        recommendLevel.setData(res.data.content);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showPopup = (no, nickname) => {
    openPopup({
      content: HistoryDetail,
      title: `${t("sectionTitle.detail_view")} (${nickname})`,
      $maxWidth: "1280px",
      $height: "95vh",
      $pad: "24px 0",
      $conPad: "0 50px",
      $radius: "16px",
      isResponsive: true,
      props: {
        no,
      },
    });
  };

  const getRecommendHistory = () => {
    setLoading(true);

    const apiUrl = "/v1/refer/history";

    api
      .get(apiUrl, {
        params: {
          ...getValue.formValue,
          ...recommendHistory.extendData.pagingData,
        },
      })
      .then((res) => {
        recommendHistory.setData(res.data.content);
        if (res.data.pageHelperDto) {
          recommendHistory.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        recommendHistory.setError("");
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        recommendHistory.setData([]);
        recommendHistory.setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const agentTransfer = () => {
    postRequest({
      url: "/v1/user/agent",
      body: {},
      Func: () => getRecommend(),
    });
  };

  const recommendInfo = useGetData({
    firstInit: true,
    api: getRecommend,
  });

  const recommendLevel = useGetData({
    firstInit: true,
    api: getLevels,
  });

  const recommendHistory = useGetData({
    firstInit: true,
    api: getRecommendHistory,
    pageSize: "10",
  });

  const getValue = useFormData(
    {
      end: "",
      start: "",
      value: "",
      user_lv: "",
      order: "JOIN",
    },
    getRecommendHistory,
    recommendHistory
  );

  const getInfo = recommendInfo.data;
  const getLevel = recommendLevel.data;
  const getHistory = recommendHistory.data;

  if (!getInfo && !getHistory && !getLevel) return null;

  return (
    <FlexBox $gap={isMiddleD && "28px"} $direct={isMiddleD ? "row" : "column"}>
      <MyMenu />

      <MyPageLayout $overX="hidden" $maxWidth="100%">
        <Column $gap="32px" $width="100%">
          <Column $gap="16px">
            <ContentBox $radius="12px" $pad="14px 20px" $backColor="var(--c-gray-700)">
              <FlexBox
                $width="100%"
                $align={isMaxMiddleM ? "flex-start" : "center"}
                $jus={!isMaxMiddleM && "space-between"}
                $direct={isMaxMiddleM ? "column" : "row"}
              >
                <Row $gap="8px">
                  <TextBox $class={"title"}>{t("common.my_referral_member")}</TextBox>
                  <Row $gap="4px">
                    <TextBox $class={["title", "white"]}>{getInfo?.my_refer_user_count}</TextBox>
                    <TextBox $class={"title"}>{t("common.member")}</TextBox>
                  </Row>
                </Row>
                {!getInfo?.is_agent && getInfo?.is_change_agent_user && (
                  <P_GradientBtn
                    $mar={isMaxMiddleM && "10px 0 0"}
                    $width={isMaxMiddleM ? "100%" : "fit-content"}
                    $pad="12px 16px"
                    $radius="12px"
                    disabled={getInfo?.is_duplicate_change_agent}
                    onClick={agentTransfer}
                  >
                    {t("button.agent_transfer")}
                  </P_GradientBtn>
                )}
              </FlexBox>
            </ContentBox>

            <Grid>
              <P_GradientBox $tAlign="left" $radius="12px" $pad={isMaxMiddleM ? "14px 16px" : "14px 20px"}>
                <Row $gap="3px" $align="center">
                  <TextBox $class={["white", isMaxMiddleM ? "captionB" : "subB"]}>
                    {t("infoMsg.today_referral_betting_money")}
                  </TextBox>
                  <MoneyIcon
                    width={isMaxMiddleM ? "16" : "20"}
                    height={isMaxMiddleM ? "16" : "20"}
                    fill="var(--c-white)"
                  />
                </Row>
                <TextBox $class={["white", isMaxMiddleM ? "title" : "header"]} $pad={!isMaxMiddleM && "4px 0 0"}>
                  {getInfo?.today_bet_money?.toLocaleString()}
                </TextBox>
              </P_GradientBox>

              <P_GradientBox $tAlign="left" $radius="12px" $pad={isMaxMiddleM ? "14px 16px" : "14px 20px"}>
                <Row $gap="3px" $align="center">
                  <TextBox $class={["white", isMaxMiddleM ? "captionB" : "subB"]}>
                    {t("infoMsg.total_referral_betting_money")}
                  </TextBox>
                  <MoneyIcon
                    width={isMaxMiddleM ? "16" : "20"}
                    height={isMaxMiddleM ? "16" : "20"}
                    fill="var(--c-white)"
                  />
                </Row>
                <TextBox $class={["white", isMaxMiddleM ? "title" : "header"]} $pad={!isMaxMiddleM && "4px 0 0"}>
                  {getInfo?.total_game_bet_money?.toLocaleString()}
                </TextBox>
              </P_GradientBox>

              <P_GradientBox $tAlign="left" $radius="12px" $pad={isMaxMiddleM ? "14px 16px" : "14px 20px"}>
                <Row $gap="3px" $align="center">
                  <TextBox $class={["white", isMaxMiddleM ? "captionB" : "subB"]}>
                    {t("infoMsg.today_referral_rolling_comp")}
                  </TextBox>
                  <PointIcon
                    width={isMaxMiddleM ? "16" : "20"}
                    height={isMaxMiddleM ? "16" : "20"}
                    fill="var(--c-white)"
                  />
                </Row>
                <TextBox $class={["white", isMaxMiddleM ? "title" : "header"]} $pad={!isMaxMiddleM && "4px 0 0"}>
                  {getInfo?.today_recommend_rolling?.toLocaleString()}
                </TextBox>
              </P_GradientBox>

              <P_GradientBox $tAlign="left" $radius="12px" $pad={isMaxMiddleM ? "14px 16px" : "14px 20px"}>
                <Row $gap="3px" $align="center">
                  <TextBox $class={["white", isMaxMiddleM ? "captionB" : "subB"]}>
                    {t("infoMsg.total_referral_rolling_comp")}
                  </TextBox>
                  <PointIcon
                    width={isMaxMiddleM ? "16" : "20"}
                    height={isMaxMiddleM ? "16" : "20"}
                    fill="var(--c-white)"
                  />
                </Row>
                <TextBox $class={["white", isMaxMiddleM ? "title" : "header"]} $pad={!isMaxMiddleM && "4px 0 0"}>
                  {getInfo?.total_recommend_rolling?.toLocaleString()}
                </TextBox>
              </P_GradientBox>

              <P_GradientBox $tAlign="left" $radius="12px" $pad={isMaxMiddleM ? "14px 16px" : "14px 20px"}>
                <Row $gap="3px" $align="center">
                  <TextBox $class={["white", isMaxMiddleM ? "captionB" : "subB"]}>
                    {t("infoMsg.today_referral_losing_money")}
                  </TextBox>
                  <PointIcon
                    width={isMaxMiddleM ? "16" : "20"}
                    height={isMaxMiddleM ? "16" : "20"}
                    fill="var(--c-white)"
                  />
                </Row>
                <TextBox $class={["white", isMaxMiddleM ? "title" : "header"]} $pad={!isMaxMiddleM && "4px 0 0"}>
                  {getInfo?.today_recommend_losing?.toLocaleString()}
                </TextBox>
              </P_GradientBox>

              <P_GradientBox $tAlign="left" $radius="12px" $pad={isMaxMiddleM ? "14px 16px" : "14px 20px"}>
                <Row $gap="3px" $align="center">
                  <TextBox $class={["white", isMaxMiddleM ? "captionB" : "subB"]}>
                    {t("infoMsg.total_referral_losing_money")}
                  </TextBox>
                  <PointIcon
                    width={isMaxMiddleM ? "16" : "20"}
                    height={isMaxMiddleM ? "16" : "20"}
                    fill="var(--c-white)"
                  />
                </Row>
                <TextBox $class={["white", isMaxMiddleM ? "title" : "header"]} $pad={!isMaxMiddleM && "4px 0 0"}>
                  {getInfo?.total_recommend_losing?.toLocaleString()}
                </TextBox>
              </P_GradientBox>
            </Grid>
          </Column>

          <Column $gap="16px">
            <TextBox $class={["title", "white"]}>{t("sectionTitle.my_comp")}</TextBox>
            <ScrollBox $pad={"20px"} $radius="12px" $backColor="var(--c-gray-700)">
              <Table className="table-box" $tLayout="fixed" $width="100%">
                <thead>
                  <tr className="table-title">
                    <TableHeader $minWidth={isMaxMiddleM && "103px"} $width={isMaxMiddleM && "103px"}>
                      {t("table.comp_type")}
                    </TableHeader>
                    <TableHeader $width={isMaxMiddleM && "103px"} $minWidth={isMaxMiddleM && "103px"}>
                      {t("table.casino")}
                    </TableHeader>
                    <TableHeader $minWidth={isMaxMiddleM && "103px"} $width={isMaxMiddleM && "103px"}>
                      {t("table.slot")}
                    </TableHeader>
                    <TableHeader $minWidth={isMaxMiddleM && "103px"} $width={isMaxMiddleM && "103px"}>
                      {t("table.mini_game")}
                    </TableHeader>
                    <TableHeader $minWidth={isMaxMiddleM && "103px"} $width={isMaxMiddleM && "103px"}>
                      {t("table.sport")}
                    </TableHeader>
                  </tr>
                </thead>
                <tbody>
                  <TableRow className="table-content">
                    <TableDeps $white={"wrap"}>{t("table.losing")}</TableDeps>
                    <TableDeps $white={"wrap"}>{getInfo?.casino_losing_rate}%</TableDeps>
                    <TableDeps $white={"wrap"}>{getInfo?.slot_losing_rate}%</TableDeps>
                    <TableDeps $white={"wrap"}>{getInfo?.mini_game_losing_rate}%</TableDeps>
                    <TableDeps $white={"wrap"}>{getInfo?.sports_losing_rate}%</TableDeps>
                  </TableRow>
                  <TableRow className="table-content">
                    <TableDeps $white={"wrap"}>{t("table.rolling")}</TableDeps>
                    <TableDeps $white={"wrap"}>{getInfo?.casino_rolling_rate}%</TableDeps>
                    <TableDeps $white={"wrap"}>{getInfo?.slot_rolling_rate}%</TableDeps>
                    <TableDeps $white={"wrap"}>{getInfo?.mini_game_rolling_rate}%</TableDeps>
                    <TableDeps $white={"wrap"}>{getInfo?.sports_rolling_rate}%</TableDeps>
                  </TableRow>
                </tbody>
              </Table>
            </ScrollBox>
          </Column>

          <Column $gap="16px">
            <TextBox $class={["title", "white"]}>{t("sectionTitle.referral_history")}</TextBox>
            <Row $gap="16px" $width="100%" $flexWrap="wrap">
              <SelectForm
                pad="10px 16px"
                name={"user_lv"}
                selectType="search"
                options={getLevel && getLevel}
                conWidth={isMaxMiddleM && "100%"}
                placeholder={t("placeholder.all_member_grade")}
                {...getValue}
              />

              <SearchCompo
                name="value"
                radius="12px"
                pad="10px 16px"
                type={"each"}
                placeholder={t("placeholder.nickname_search")}
                conColor="var(--c-gray-700)"
                placeColor="var(--c-gray-300)"
                conWidth={isMaxMiddleM ? "100%" : "158px"}
                {...getValue}
              />

              <SelectForm
                pad="10px 16px"
                name={"order"}
                selectType="search"
                options={enums?.joinState}
                conWidth={isMaxMiddleM && "100%"}
                placeholder={t("placeholder.join_date")}
                {...getValue}
              />

              <CalendarFilter endName="end" startName="start" {...getValue} />

              <P_GradientBtn
                $radius="12px"
                $pad="10px 16px"
                $width={isMaxMiddleM ? "100%" : "fit-content"}
                onClick={() => getValue.formSearch()}
              >
                {t("button.search")}
              </P_GradientBtn>
            </Row>
            {getHistory?.length === 0 ? (
              <NoListLayout />
            ) : (
              <ScrollBox $pad={"20px"} $radius="12px" $backColor="var(--c-gray-700)">
                <Table className="table-box" $tLayout="fixed" $width="100%">
                  <thead>
                    <tr className="table-title">
                      <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                        No
                      </TableHeader>
                      <TableHeader $width={isScroll && "136px"} $minWidth={isScroll && "136px"}>
                        {t("table.join_date")}
                      </TableHeader>
                      <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                        {t("table.nickname")}
                      </TableHeader>
                      <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                        {t("table.member_grade")}
                      </TableHeader>
                      <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                        {t("table.last_login_date")}
                      </TableHeader>
                      <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                        <Row $gap="4px" $jus="center">
                          <TextBox>{t("table.total_betting_money")}</TextBox>
                          <Empty $width="20px" $height="20px">
                            <MoneyIcon width="20" height="20" />
                          </Empty>
                        </Row>
                      </TableHeader>
                      <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                        <Row $gap="4px" $jus="center">
                          <TextBox>{t("table.total_rolling_comp")}</TextBox>
                          <Empty $width="20px" $height="20px">
                            <PointIcon width="20" height="20" />
                          </Empty>
                        </Row>
                      </TableHeader>
                      <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                        <Row $gap="4px" $jus="center">
                          <TextBox>{t("table.total_losing_money")}</TextBox>
                          <Empty $width="20px" $height="20px">
                            <PointIcon width="20" height="20" />
                          </Empty>
                        </Row>
                      </TableHeader>
                      <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                        {t("table.management")}
                      </TableHeader>
                    </tr>
                  </thead>
                  <tbody>
                    {getHistory &&
                      getHistory?.map(
                        (
                          {
                            no,
                            nickname,
                            level_name,
                            total_losing,
                            total_rolling,
                            last_login_at,
                            total_bet_money,
                            join_confirm_at,
                            level_icon_file_uuid,
                          },
                          index
                        ) => {
                          const number = recommendHistory.pagingInfo.offset - index;
                          return (
                            <TableRow className="table-content" key={number}>
                              <TableDeps $white={"wrap"}>{number}</TableDeps>
                              <TableDeps $white={"wrap"}>{dateCommaFormat(join_confirm_at)}</TableDeps>
                              <TableDeps $white={"wrap"}>{nickname}</TableDeps>
                              <TableDeps $white={"wrap"}>
                                <Row $jus="center" $gap="4px" $align="center">
                                  {level_icon_file_uuid && (
                                    <Thumbnail
                                      $width="20px"
                                      src={process.env.REACT_APP_IMG_URL + level_icon_file_uuid}
                                    />
                                  )}
                                  <TextBox $class={["subB", "white"]}>{level_name}</TextBox>
                                </Row>
                              </TableDeps>
                              <TableDeps $white={"wrap"}>{dateCommaFormat(last_login_at)}</TableDeps>
                              <TableDeps $white={"wrap"}>{total_bet_money?.toLocaleString()}</TableDeps>
                              <TableDeps $white={"wrap"}>{total_rolling?.toLocaleString()}</TableDeps>
                              <TableDeps $white={"wrap"}>{total_losing?.toLocaleString()}</TableDeps>
                              <TableDeps>
                                <BorderBtn
                                  onClick={() => showPopup(no, nickname)}
                                  $backColor={"var(--c-gray-700)"}
                                  $bor="1px solid var(--c-gray-600)"
                                >
                                  <TextBox $class={["white", "subB"]}>{t("button.detail")}</TextBox>
                                </BorderBtn>
                              </TableDeps>
                            </TableRow>
                          );
                        }
                      )}
                  </tbody>
                </Table>
              </ScrollBox>
            )}
          </Column>
          {getHistory?.length !== 0 && <Pagination {...recommendHistory} />}
        </Column>
      </MyPageLayout>
    </FlexBox>
  );
};

export default MyRecommend;
