const textStyles = {
  subB: `
    font-size: var(--s-sub);
    font-family: var(--f-subB);
    line-height: var(--l-sub);
  `,
  sub: `
    font-size: var(--s-sub);
    font-family: var(--f-sub);
    line-height: var(--l-sub);
  `,
  title: `
    font-size: var(--s-title);
    font-family: var(--f-title);
    line-height: var(--l-title);
  `,
  tableB: `
    font-size: var(--s-table);
    font-family: var(--f-tableB);
    line-height: var(--l-table);
  `,
  table: `
    font-size: var(--s-table);
    font-family: var(--f-table);
    line-height: var(--l-table);
  `,
  captionB: `
    font-size: var(--s-caption);
    font-family: var(--f-captionB);
    line-height: var(--l-caption);
  `,
  caption: `
    font-size: var(--s-caption);
    font-family: var(--f-caption);
    line-height: var(--l-caption);
  `,
  text: `
    font-size: var(--s-text);
    font-family: var(--f-text);
    line-height: var(--l-text);
  `,

  header: `
    font-size: var(--s-header);
    font-family: var(--f-header);
    line-height: var(--l-header);
  `,
  60: `
    font-size: var(--s-60);
    font-family: var(--f-subB);
    line-height: var(--l-60);
  `,

  gray200: `
    color: var(--c-gray-200);
  `,
  gray300: `
    color: var(--c-gray-300);
  `,
  gray400: `
    color: var(--c-gray-400);
  `,
  gray600: `
    color: var(--c-gray-600);
  `,
  gray700: `
    color: var(--c-gray-700);
  `,
  gray800: `
    color: var(--c-gray-800);
  `,
  gray900: `
    color: var(--c-gray-900);
  `,

  purple: `
    color: var(--c-purple);
  `,

  white: `
    color: var(--c-white);
  `,

  hover: `
    color: var(--c-hover);
  `,

  red: `
  color: var(--c-red)
  `,

  blue: `
  color : var(--c-blue)
  `,
};

export { textStyles };
