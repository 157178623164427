import React from "react";
import { useTranslation } from "react-i18next";

import {
  RecentIcon,
  EventIcon,
  BestIcon,
  MyPageIcon,
  DepositIcon,
  WithdrawICon,
  ServiceIcon,
  NoticeIcon,
  HotIcon,
  RecommendGameIcon,
} from "assets/components/IconComponent";

const useHeaderMenu = () => {
  const { t } = useTranslation();

  return [
    {
      title: t("support.event"),
      groupEnum: "H_EVENT",
      route: "/event",
      key: "3",
      IconComponent: <EventIcon />,
      iconProps: {
        width: "20",
        height: "20",
        fill: "var(--c-gray-300)",
        activeFill: "var(--c-green)",
      },
    },
    {
      title: t("support.notice"),
      groupEnum: "H_NOTICE",
      route: "/notice",
      key: "4",
      IconComponent: <NoticeIcon />,
      iconProps: {
        width: "20",
        height: "20",
        fill: "var(--c-gray-300)",
        activeFill: "var(--c-pink)",
      },
    },
  ];
};

const useForYouMenu = () => {
  const { t } = useTranslation();

  return {
    RECENT_BATTING: {
      groupTitle: t("forYou.recent_games"),
      groupRoute: "/recent_games",
      groupEnum: "RECENT_BATTING",
      key: "1",
      IconComponent: <RecentIcon />,
      iconProps: {
        width: "22",
        height: "22",
        name: "schedule",
        fill: "var(--c-gray-300)",
      },
    },
    NEW_GAME: {
      groupTitle: t("forYou.new_games"),
      groupRoute: "/new_games",
      groupEnum: "NEW_GAME",
      key: "2",
      IconComponent: <BestIcon />,
      iconProps: {
        width: "22",
        height: "22",
        fill: "var(--c-gray-300)",
      },
    },
    HOT_GAME: {
      groupTitle: t("forYou.hot_games"),
      groupRoute: "/popular_games",
      groupEnum: "HOT_GAME",
      key: "3",
      IconComponent: <HotIcon />,
      iconProps: {
        width: "22",
        height: "22",
        fill: "var(--c-gray-300)",
      },
    },
    RECOMMEND_GAME: {
      groupTitle: t("forYou.recommended_games"),
      groupRoute: "/recommended_games",
      groupEnum: "RECOMMEND_GAME",
      key: "4",
      IconComponent: <RecommendGameIcon />,
      iconProps: {
        width: "22",
        height: "22",
        fill: "var(--c-gray-300)",
      },
    },
  };
};

const useMyPageMenu = () => {
  const { t } = useTranslation();

  return {
    MY_PAGE: {
      groupTitle: t("myMenu.my_profile"),
      groupRoute: "/my.page",
      groupEnum: "MY_PAGE",
      key: "1",
      IconComponent: <MyPageIcon />,
      iconProps: {
        width: "22",
        height: "22",
        fill: "var(--c-gray-300)",
      },
    },
    DEPOSIT: {
      groupTitle: t("myMenu.deposit"),
      groupRoute: "/deposit",
      groupEnum: "DEPOSIT",
      key: "2",
      IconComponent: <DepositIcon />,
      iconProps: {
        width: "22",
        height: "22",
        fill: "var(--c-gray-300)",
      },
    },
    WITH_DRAW: {
      groupTitle: t("myMenu.withdraw"),
      groupRoute: "/withdraw",
      groupEnum: "WITH_DRAW",
      key: "3",
      IconComponent: <WithdrawICon />,
      iconProps: {
        width: "22",
        height: "22",
        fill: "var(--c-gray-300)",
      },
    },
  };
};

const useSupport = () => {
  const { t } = useTranslation();

  return {
    SERVICE: {
      IconComponent: <ServiceIcon />,
      iconProps: {
        width: "22",
        height: "22",
        fill: "var(--c-gray-300)",
      },
      notAuth: "NOT_AUTH",
      groupTitle: t("support.customer"),
      groupRoute: "/customer",
      groupEnum: "SERVICE",
      key: "1",
    },
    NOTICE: {
      IconComponent: <NoticeIcon />,
      iconProps: {
        width: "22",
        height: "22",
        fill: "var(--c-gray-300)",
      },
      groupTitle: t("support.notice"),
      groupRoute: "/notice",
      groupEnum: "NOTICE",
      key: "2",
    },
    EVENT: {
      IconComponent: <EventIcon />,
      iconProps: {
        width: "22",
        height: "22",
        fill: "var(--c-gray-300)",
      },
      groupTitle: t("support.event"),
      groupRoute: "/event",
      groupEnum: "EVENT",
      key: "3",
    },
  };
};

export { useHeaderMenu, useMyPageMenu, useForYouMenu, useSupport };
