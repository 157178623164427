import React from "react";
import { useNavigate } from "react-router-dom";

import { postRequest } from "apis/postRequest";
import { useMedia } from "util/useMedia";
import { InputForm, TextareaForm } from "data/useForm";

import { InputCon } from "assets/Styles/SInput";
import { RequiredText, TextBox } from "assets/Styles/SText";
import { PrevIcon } from "assets/components/IconComponent";
import { ActionBtn, P_GradientBtn } from "assets/Styles/SButton";
import { FlexBox, MyPageLayout, Empty } from "assets/Styles/SLayout";
import { Column, Row, RowLink } from "assets/Styles/custom/CustomLayout";

import MyMenu from "../../my/layout/MyMenu";
import useFormData from "data/useFormData";
import { t } from "i18next";

const QnaContact = () => {
  const navigate = useNavigate();
  const { isMiddleD } = useMedia();

  const getValue = useFormData({
    title: "",
    contents: "",
  });
  const formValue = getValue.formValue;

  const QnaPost = () => {
    postRequest({
      url: "/v1/user/qna",
      body: { ...formValue },
      Func: () => navigate("/customer/qna"),
    });
  };
  return (
    <FlexBox $gap={isMiddleD && "28px"} $direct={isMiddleD ? "row" : "column"}>
      <MyMenu />

      <MyPageLayout>
        <Column $gap="24px">
          <RowLink to="/customer/qna" $gap="4px">
            <PrevIcon width="16" height="16" fill="var(--c-gray-300)" />
            <TextBox $class="gray300">{t("button.return_list")}</TextBox>
          </RowLink>

          <TextBox $class={["title", "white"]}>{t("sectionTitle.inquiry")}</TextBox>

          <Empty>
            <RequiredText>{t("common.title")}</RequiredText>
            <InputCon $pad="12px 16px" $radius="12px">
              <InputForm name="title" placeholder={t("placeholder.inquiry_title")} {...getValue} />
            </InputCon>
          </Empty>

          <Empty>
            <RequiredText>{t("common.inquiry_content")}</RequiredText>
            <InputCon $pad="12px 16px" $radius="12px" $height="240px">
              <TextareaForm name="contents" placeholder={t("placeholder.inquiry_content")} {...getValue} />
            </InputCon>
          </Empty>
          <Row $gap="12px">
            <ActionBtn
              as="a"
              href="/customer/qna"
              $width="100%"
              $pad="12px 0"
              $radius="12px"
              $tAlign="center"
              $font="var(--f-subB)"
            >
              {t("button.call_off")}
            </ActionBtn>
            <P_GradientBtn
              $pad="12px 0"
              $radius="12px"
              onClick={QnaPost}
              disabled={formValue.title?.length === 0 || formValue.contents?.length === 0}
            >
              {t("button.register_inquiry")}
            </P_GradientBtn>
          </Row>
        </Column>
      </MyPageLayout>
    </FlexBox>
  );
};

export default QnaContact;
