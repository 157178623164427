import React, { useEffect, useRef, useState } from "react";
import { EventSourcePolyfill } from "event-source-polyfill";
import { useLocation, useNavigate } from "react-router-dom";
import i18n, { changeLanguage } from "locales/i18n";
import styled from "styled-components";
import { isEqual } from "lodash";
import { t } from "i18next";

import defaultProfile from "assets/images/common/default_profile.png";
import { BellIcon, DepositIcon, LanguageIcon, LinkIcon, MoneyIcon, PointIcon } from "assets/components/IconComponent";

import { useMedia } from "util/useMedia";
import { TextBox } from "assets/Styles/SText";
import { GlassBox, PurpleGBox, HoverBox, Row } from "assets/Styles/custom/CustomLayout";
import {
  AssetsCash,
  HeaderLayout,
  HeaderBetween,
  MainMenu,
  HD,
  MyCashBox,
  QuickMenu,
} from "assets/Styles/componentstyle/HeaderStyle";
import { ActionBtn } from "assets/Styles/SButton";
import { Img, PositionBox } from "assets/Styles/SLayout";

import { usePopup } from "context/useWindow";
import { useCommonState } from "context/useContext";

import MHeader from "./MHeader";
import api from "interceptor/api";
import Login from "../popup/Login";

import { useMenu } from "constant/useMenu";
import { unitFormat } from "util/Formatter";
import { useHeaderMenu } from "enum/menu/useMenu";
import { useStateCheck } from "util/useStateCheck";
import { getIconComponent } from "util/IconMatching";
import useLogout from "components/hooks/useLogout";
import LngValue from "enum/LngValue";
import useGetEnum from "enum/useGetEnum";
import useOutsideBlur from "components/hooks/useOutSideBlur";
import useGetUser from "apis/useGetUser";

const DropdownCon = styled.div`
  margin-top: 8px;
  position: absolute;
  right: 0;
  top: 100%;

  z-index: 99;

  min-width: 132px;
  border-radius: 12px;
  background-color: var(--c-gray-700);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.3);
`;

const DropdownUl = styled.ul`
  padding: 6px;
`;

const DropdownLi = styled.li`
  padding: 8px 16px;
  margin-bottom: 6px;
  white-space: nowrap;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    border-radius: 8px;
    background-color: var(--c-gray-600);
  }
`;

const Header = ({ menuSlide, menuToggle }) => {
  const enums = useGetEnum();
  const menuItems = useMenu();
  const navigate = useNavigate();
  const stateCheck = useStateCheck();
  const headerMenu = useHeaderMenu();
  const { logout } = useLogout();
  const { openPopup } = usePopup();
  const { pathname } = useLocation();
  const { isMiddleD, isMiddleM, isMaxMiddleM, isMaxMobile } = useMedia();
  const { isLogin, accessToken, eventSource, setEventSource, userType } = useCommonState();
  const [gameMenus, setGameMenus] = useState([]);

  const { ref: profileRef, isVisible: isProfile, setIsVisible: setIsProfile } = useOutsideBlur(false);
  const { ref: languageRef, isVisible: isLanguage, setIsVisible: setIsLanguage } = useOutsideBlur(false);

  const profileDrop = () => {
    setIsProfile((prev) => !prev);
  };

  const languageDrop = () => {
    setIsLanguage((prev) => !prev);
  };

  const showPopup = () => {
    openPopup({
      content: Login,
      title: t("sectionTitle.login"),
    });
  };

  const updateTableSubMenu = () => {
    const url = "/v1/game/side-menu";
    api
      .get(url)
      .then((res) => {
        const menus = res.data.content;
        if (!menus || menus.length === 0) {
          console.error("Menu data is empty or undefined.");
          return;
        }
        setGameMenus(menus);
      })
      .catch((err) => {
        console.error("Error fetching menu:", err);
      });
  };

  useEffect(() => {
    updateTableSubMenu();
  }, []);

  useEffect(() => {
    if (!accessToken) return;

    const sseUrl = `${process.env.REACT_APP_BASE_URL}/v1/user/header-alarm`;
    const sseHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const sse = new EventSourcePolyfill(sseUrl, sseHeaders);

    try {
      sse.addEventListener("update", (e) => {
        if (e?.data) {
          try {
            const userData = JSON.parse(e.data);
            if (!isEqual(userData, eventSource)) {
              setEventSource(userData);
            }
          } catch (error) {
            console.error("Error parsing update event data:", error);
          }
        }
      });

      sse.addEventListener("ping", (e) => {
        if (e?.data) {
          try {
            const pingData = JSON.parse(e.data);
          } catch (error) {
            console.error("Error parsing ping event data:", error);
          }
        }
      });

      sse.onerror = () => {
        sse.close();
      };

      return () => {
        sse.close();
      };
    } catch (error) {
      console.error("Error initializing SSE:", error);
      sse.close();
    }
  }, [accessToken, isLogin]);

  return (
    <HD as="header">
      {isMaxMiddleM && <MHeader menuSlide={menuSlide} menuToggle={menuToggle} />}
      <HeaderLayout>
        <HeaderBetween>
          {isMiddleD && (
            <QuickMenu $gap="6px">
              {gameMenus.map((menu) =>
                menu.subMenus.map((subMenu) => {
                  const formattedPath = `/${subMenu.name.trim().replace(/\s+/g, "")}/${subMenu.menuCode}`;
                  const isActive =
                    pathname === formattedPath ||
                    subMenu.subMenus.some(
                      (sub) => pathname === `/${sub.name.trim().replace(/\s+/g, "")}/${sub.menuCode}`
                    );
                  return (
                    <MainMenu key={subMenu.menuCode} className={isActive && "active"}>
                      <Row
                        $gap="8px"
                        $align="center"
                        onClick={() => stateCheck(`/${subMenu.name.trim().replace(/\s+/g, "")}/${subMenu.menuCode}`)}
                      >
                        {React.cloneElement(getIconComponent(subMenu.icon), {
                          width: "20",
                          height: "20",
                          fill: isActive ? "var(--c-purple)" : "var(--c-gray-300)",
                        })}
                        <TextBox $white="nowrap" $class={isActive ? ["subB", "white"] : ["sub"]}>
                          {subMenu.name}
                        </TextBox>
                      </Row>
                    </MainMenu>
                  );
                })
              )}

              {headerMenu.map(({ key, title, route, IconComponent, iconProps, GROUP }) => {
                const isActive = pathname.includes(route);

                return (
                  <MainMenu key={key} className={isActive && "active"}>
                    <Row $gap="8px" $align="center" onClick={() => stateCheck(route)}>
                      {React.cloneElement(IconComponent, {
                        ...iconProps,
                        fill: isActive ? iconProps.activeFill : iconProps.fill,
                      })}
                      <TextBox $white="nowrap" $class={isActive ? ["subB", "white"] : ["sub"]}>
                        {title}
                      </TextBox>
                    </Row>
                  </MainMenu>
                );
              })}
            </QuickMenu>
          )}

          <Row $gap="12px" $align="center">
            {isLogin && (
              <>
                <GlassBox>
                  <MyCashBox $gap="10px" $pad="0 16px">
                    <Row $gap="4px" $align="center">
                      <MoneyIcon
                        fill="var(--c-gray-300)"
                        width={isMaxMobile ? "16" : "24"}
                        height={isMaxMobile ? "16" : "24"}
                      />
                      <AssetsCash $class={["white", "title"]}>
                        {(eventSource && unitFormat(eventSource?.money)) || 0}
                      </AssetsCash>
                    </Row>
                    <Row $gap="4px" $align="center">
                      <PointIcon
                        fill="var(--c-gray-300)"
                        width={isMaxMobile ? "16" : "24"}
                        height={isMaxMobile ? "16" : "24"}
                      />
                      <AssetsCash $class={["white", "title"]}>
                        {(eventSource && unitFormat(eventSource?.point)) || 0}
                      </AssetsCash>
                    </Row>
                  </MyCashBox>
                  {isMiddleM && (
                    <PurpleGBox
                      $gap="8px"
                      $pad="0 18px"
                      $height="44px"
                      $align="center"
                      onClick={() => stateCheck("/deposit")}
                    >
                      <DepositIcon width="18" height="18" fill="white" />
                      <TextBox $class={["white", "subB"]} $white="nowrap">
                        {t("common.deposit")}
                      </TextBox>
                    </PurpleGBox>
                  )}
                </GlassBox>
                <PositionBox ref={profileRef} $height={isMaxMobile ? "32px" : "44px"} $position="relative">
                  <Img onClick={profileDrop} $width={isMaxMobile && "32px"} src={defaultProfile} alt="profile" />
                  {isProfile && (
                    <DropdownCon>
                      <DropdownUl>
                        {userType === "D" && (
                          <DropdownLi>
                            <Row as="a" href="https://agent.kup-game.shop" target="_blank" $align="center" $gap="6px">
                              <TextBox>{t("common.agent_manager")}</TextBox>
                              <LinkIcon width="16" height="16" />
                            </Row>
                          </DropdownLi>
                        )}
                        {menuItems.map(({ name, key, route }) => (
                          <DropdownLi
                            key={key}
                            onClick={() => {
                              if (route) {
                                stateCheck(route[0]);
                              } else {
                                logout();
                              }
                              setIsProfile(false);
                            }}
                          >
                            {name}
                          </DropdownLi>
                        ))}
                      </DropdownUl>
                    </DropdownCon>
                  )}
                </PositionBox>
                {isMiddleM && (
                  <HoverBox $width="44px" $height="44px" $backColor="var(--c-hover)" $radius="999px" $gap="12px">
                    <PositionBox
                      $position="relative"
                      $width="44px"
                      $height="44px"
                      $dis="flex"
                      $align="center"
                      $jus="center"
                      onClick={() => stateCheck("/note")}
                    >
                      <BellIcon />
                      {eventSource.total_unread_messages > 0 && (
                        <PositionBox $position="absolute" $top="-2px" $right="-4px">
                          <ActionBtn
                            $pad="0 6px"
                            $radius="10px"
                            $backColor={eventSource.is_alarm === 1 && "var(--c-pGradient)"}
                          >
                            <TextBox $class={["captionB", "white"]}>{eventSource.total_unread_messages}</TextBox>
                          </ActionBtn>
                        </PositionBox>
                      )}
                    </PositionBox>
                  </HoverBox>
                )}
              </>
            )}
            {!isLogin && (
              <>
                {!pathname.includes("sign_up") && (
                  <TextBox
                    onClick={showPopup}
                    $width={isMaxMobile ? "60px" : "73px"}
                    $tAlign="center"
                    $class={isMaxMobile ? ["captionB", "white"] : ["subB", "white"]}
                    $cursor="pointer"
                  >
                    {t("button.login")}
                  </TextBox>
                )}
                <PurpleGBox
                  onClick={() => navigate("/sign")}
                  $align="center"
                  $height={isMaxMobile ? "32px" : "44px"}
                  $pad={isMaxMobile ? "0 14px" : "0 18px"}
                >
                  <TextBox $class={isMaxMobile ? ["captionB", "white"] : ["subB", "white"]} $white="nowrap">
                    {t("button.sign_up")}
                  </TextBox>
                </PurpleGBox>
              </>
            )}
            {!isMaxMiddleM && (
              <HoverBox
                $width={isMaxMobile ? "32px" : "44px"}
                $height={isMaxMobile ? "32px" : "44px"}
                $backColor="var(--c-hover)"
                $radius="999px"
                $gap="12px"
              >
                <PositionBox
                  ref={languageRef}
                  $position="relative"
                  $height={isMaxMobile ? "16px" : "20px"}
                  onClick={languageDrop}
                >
                  <LanguageIcon width={isMaxMobile ? "16" : "20"} height={isMaxMobile ? "16" : "20"} />
                  {isLanguage && (
                    <DropdownCon>
                      <DropdownUl>
                        {enums.lngValue.map(({ title, value }) => {
                          const isActive = i18n.language === value;
                          return (
                            <DropdownLi key={value} onClick={() => changeLanguage(value)}>
                              <TextBox $class={isActive && ["white", "subB"]}>{title}</TextBox>
                            </DropdownLi>
                          );
                        })}
                      </DropdownUl>
                    </DropdownCon>
                  )}
                </PositionBox>
              </HoverBox>
            )}
          </Row>
        </HeaderBetween>
      </HeaderLayout>
    </HD>
  );
};

export default Header;
