import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useCookies } from "react-cookie";
import { Row } from "assets/Styles/custom/CustomLayout";
import { P_GradientBox } from "assets/Styles/SButton";
import { PositionBox } from "assets/Styles/SLayout";
import { HeaderCloseIcon } from "assets/components/IconComponent";
import { useMedia } from "util/useMedia";
import api from "interceptor/api";
import useGetData from "data/useGetData";
import { useCommonState } from "context/useContext";

const flowText = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
`;

const FlowingText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  animation: ${flowText} 8s linear infinite;

  font-family: var(--f-title);
  font-size: var(--s-title);
  line-height: var(--l-title);
  color: var(--c-white);
`;

const COOKIE_NAME = "HBB_Cookie";

const InspectionPopup = () => {
  const [cookies, setCookie] = useCookies([COOKIE_NAME]);
  const [isVisible, setIsVisible] = useState(false);
  const { isLogin } = useCommonState();
  const { isMaxMiddleM } = useMedia();

  const getInspection = () => {
    const apiUrl = "/v1/site/scroll";
    api
      .get(apiUrl)
      .then((res) => {
        getInspectionInfo.setData(res.data.content);
        setIsVisible(res.data.content.is_inspection_visible && !cookies[COOKIE_NAME]);
      })
      .catch((err) => {
        console.error(err);
        setIsVisible(false);
      });
  };

  const getInspectionInfo = useGetData({
    api: getInspection,
    firstInit: true,
  });

  useEffect(() => {
    if (getInspectionInfo.data) {
      setIsVisible(getInspectionInfo.data.is_inspection_visible && !cookies[COOKIE_NAME]);
    }
  }, [cookies[COOKIE_NAME], getInspectionInfo.data]);

  const handleClose = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    setCookie(COOKIE_NAME, true, { path: "/", expires: tomorrow });
    setIsVisible(false);
  };

  const getInfo = getInspectionInfo.data;
  if (!isVisible || !getInfo || !isLogin) return null;

  return (
    <PositionBox $zIndex="1000" $position="fixed" $top="100px" $right={isMaxMiddleM ? "16px" : "40px"}>
      <P_GradientBox $width="328px" $radius="30px">
        <Row $overX="hidden" $pad="14px 0">
          <Row $pad="0 18px">
            <HeaderCloseIcon width="24" height="24" onClick={handleClose} />
          </Row>
          <Row $width="100%" $overX="hidden">
            <FlowingText>{getInfo?.contents}</FlowingText>
          </Row>
        </Row>
      </P_GradientBox>
    </PositionBox>
  );
};

export default InspectionPopup;
