import React, { createContext, useContext, useEffect, useState, useMemo } from "react";
import { jwtDecode } from "jwt-decode";
import api from "interceptor/api";

const CommonContext = createContext();

const checkIsLogin = () => {
  const accessToken = localStorage.getItem("access-token");
  const refreshToken = localStorage.getItem("refresh-token");

  return accessToken && refreshToken;
};

export const CommonProvider = ({ children }) => {
  const [error, setError] = useState();
  const [userType, setUserType] = useState();

  const [signUp, setSignUp] = useState(null);
  const [loading, setLoading] = useState(false);

  const [userState, setUserState] = useState("");

  const [inputError, setInputError] = useState({});
  const [eventSource, setEventSource] = useState({});

  const [isLogin, setIsLogin] = useState(checkIsLogin);
  const [readNote, setReadNote] = useState({});

  const accessToken = localStorage.getItem("access-token");
  const decoded = accessToken ? jwtDecode(accessToken) : null;
  const userNo = decoded ? decoded.no : null;

  const getUser = async () => {
    setLoading(true);
    try {
      const res = await api.get("/v1/user");
      setUserState(res.data.content.state1);
      setSignUp(res.data.content.is_exist_user_data);
      setUserType(res.data.content.type);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isLogin) {
      getUser();
    }
  }, [isLogin]);

  const contextValue = {
    loading,
    setLoading,

    isLogin,
    setIsLogin,

    inputError,
    setInputError,

    error,
    setError,

    eventSource,
    setEventSource,

    signUp,
    userState,

    readNote,
    setReadNote,

    userNo,
    decoded,
    userType,
    accessToken,
  };

  return <CommonContext.Provider value={contextValue}>{children}</CommonContext.Provider>;
};

export const useCommonState = () => {
  const context = useContext(CommonContext);
  if (!context) {
    throw new Error("useCommonContext must be used within a CommonProvider");
  }
  return context;
};
