import React from "react";
import { TextBox } from "assets/Styles/SText";
import styled from "styled-components";

const Titled = styled(TextBox)`
  font-size: var(--s-header);
  font-family: var(--f-header);
  line-height: var(--l-header);
  color: var(--c-white);
  @media (max-width: 700px) {
    font-size: var(--s-title);
    font-family: var(--f-title);
    line-height: var(--l-title);
  }
`;

export const SectionTitle = ({ name }) => {
  return <Titled>{name}</Titled>;
};
