import { Between, ContentBox, Thumbnail } from "assets/Styles/custom/CustomLayout";
import { TextBox } from "assets/Styles/SText";
import { useCommonState } from "context/useContext";
import useGetData from "data/useGetData";
import { t } from "i18next";
import api from "interceptor/api";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import styled from "styled-components";

const BannerPopBox = styled.div`
  width: 400px;
  height: 100%;
  position: absolute;
  top: ${({ $top }) => ($top ? `${$top}px` : "50%")};
  left: ${({ $left }) => ($left ? `${$left}px` : "50%")};
  transform: ${({ $top, $left }) => ($top && $left ? "none" : "translate(-50%, -50%)")};
  z-index: 10000;

  @media (max-width: 850px) {
    width: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const BannerThumbnail = styled.div`
  width: 100%;
  height: 600px;
  background-color: var(--c-gray-900);
  border-radius: 12px 12px 0 0;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.3);

  @media (max-width: 850px) {
    height: 450px;
  }
`;

const BannerContent = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--c-gray-900);
  border-radius: 12px 12px 0 0;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.3);

  @media (max-width: 850px) {
    height: 450px;
  }
`;

const COOKIE_NAME = "Popup_Cookie";

const BannerPopup = () => {
  const [cookies, setCookie] = useCookies([COOKIE_NAME]);
  const [isVisible, setIsVisible] = useState(true);
  const { isLogin } = useCommonState();

  const getBannerPopup = () => {
    const apiUrl = "/v1/site/popup";
    api
      .get(apiUrl)
      .then((res) => {
        getPopupData.setData(res.data.content);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getPopupData = useGetData({
    api: getBannerPopup,
    firstInit: true,
  });

  const getPopup = getPopupData.data;

  useEffect(() => {
    if (getPopup) {
      const shouldShow =
        getPopup.is_inspection_visible &&
        !cookies[COOKIE_NAME] &&
        (getPopup.inspec_popup_expo_yn === "Y" || (getPopup.inspec_popup_expo_yn === "N" && isLogin));
      setIsVisible(shouldShow);
    }
  }, [cookies[COOKIE_NAME], getPopup, isLogin]);

  const handleHideForDay = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    setCookie(COOKIE_NAME, true, { path: "/", expires: tomorrow });
    setIsVisible(false);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible || !getPopup || !getPopup.is_inspection_visible || (getPopup.inspec_popup_expo_yn === "N" && !isLogin))
    return null;

  return (
    <BannerPopBox
      $top={getPopup.popup_location_type === "F" && getPopup.popup_top}
      $left={getPopup.popup_location_type === "F" && getPopup.popup_left}
    >
      {getPopup.inspec_popup_contents && !getPopup.inspec_popup_image_uuid && (
        <BannerContent>
          <TextBox $class={["title", "white"]}>{getPopup.inspec_popup_contents}</TextBox>
        </BannerContent>
      )}
      {getPopup.inspec_popup_image_uuid && (
        <BannerThumbnail>
          <Thumbnail
            $width="100%"
            $height="100%"
            $fit="contain"
            src={process.env.REACT_APP_IMG_URL + getPopup?.inspec_popup_image_uuid}
          />
        </BannerThumbnail>
      )}
      <ContentBox $shadow="0px 4px 20px 0px rgba(0, 0, 0, 0.3);" $backColor="var(--c-gray-600)" $radius="0 0 12px 12px">
        <Between $pad="16px 20px" $width="100%">
          <TextBox as="button" $cursor="pointer" onClick={handleHideForDay} $class={["title", "white"]}>
            {t("button.hide_today")}
          </TextBox>
          <TextBox as="button" $cursor="pointer" onClick={handleClose} $class={["title", "white"]}>
            {t("button.close")}
          </TextBox>
        </Between>
      </ContentBox>
    </BannerPopBox>
  );
};

export default BannerPopup;
