import styled, { css } from "styled-components";

const createTable = ({ $width, $maxWidth, $tLayout }) => css`
  max-width: ${$maxWidth};
  text-align: center;
  white-space: nowrap;
  table-layout: ${$tLayout};

  border: none;
  border-spacing: 0;
  border-style: hidden;
  border-collapse: collapse;
  width: ${$width};
`;

const createTableHeader = ({ $pad, $width, $height, $tAlign, $minWidth, $maxWidth, $minHeight }) => css`
  height: ${$height};
  min-height: ${$minHeight};

  width: ${$width};
  min-width: ${$minWidth};
  max-width: ${$maxWidth};

  padding: ${$pad ? $pad : "0 20px 20px 0"};
  text-align: ${$tAlign};

  vertical-align: middle;

  &:first-child {
    padding-left: 16px;
  }
  &:last-child {
    padding-right: 16px;
  }
`;

const createTableDeps = ({ $pad, $color, $width, $white, $height, $textDeco, $maxWidth, $minHeight, $tAlign }) => css`
  height: ${$height};
  min-height: ${$minHeight};

  width: ${$width};
  max-width: ${$maxWidth};

  padding: ${$pad ? $pad : "12px 20px 12px 0"};
  vertical-align: middle;

  text-align: ${$tAlign};
  text-decoration: ${$textDeco};
  color: ${$color};
  font-family: var(--f-subB);

  white-space: ${$white};

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding-left: 16px;
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-right: 16px;
  }
`;

const createRecentTableDeps = ({
  $pad,
  $color,
  $width,
  $white,
  $height,
  $textDeco,
  $maxWidth,
  $minHeight,
  $tAlign,
}) => css`
  height: ${$height};
  min-height: ${$minHeight};

  width: ${$width};
  max-width: ${$maxWidth};

  padding: ${$pad ? $pad : "0 28px 0 0"};
  vertical-align: middle;

  text-align: ${$tAlign};
  text-decoration: ${$textDeco};
  color: ${$color};
  font-family: var(--f-subB);

  white-space: ${$white};

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding-left: 16px;
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-right: 16px;
  }
`;

const Table = styled.table`
  ${createTable}
`;

const TableHeader = styled.th`
  ${createTableHeader}
`;

const TableDeps = styled.td`
  ${createTableDeps}
`;
const RecentTableDeps = styled.td`
  ${createRecentTableDeps}
`;

const RecentTableH = styled(TableHeader)`
  padding: 0 28px 20px 0;

  &:first-child {
    padding: 0 0 20px 16px;
  }
  &:last-child {
    padding: 0 16px 20px 0;
  }
`;

const TableRow = styled.tr`
  background-color: var(--c-gray-600);
  height: 48px;
  &:nth-child(even) {
    background-color: transparent;
  }
`;

export { Table, TableRow, TableDeps, TableHeader, RecentTableH, RecentTableDeps };
