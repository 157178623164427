import { useMediaQuery } from "react-responsive";

/**
 * @description 반응형 디자인 시 사용될 미디어쿼리 함수
 * @returns {Object} 미디어 쿼리 결과 객체
 * @returns {boolean} isBanner
 * @returns {boolean} isTablet
 * @returns {boolean} isScroll
 * @returns {boolean} isMobile
 * @returns {boolean} isDesktop
 * @returns {boolean} isMiddleD
 * @returns {boolean} isMiddleM
 * @returns {boolean} isMaxMobile
 * @returns {boolean} isMaxTablet
 * @returns {boolean} isMaxMiddleM
 * @returns {boolean} isMaxDesktop
 * @returns {boolean} isMaxMiddleD
 */

const useMedia = () => {
  const isBanner = useMediaQuery({ query: "(max-width: 1681px)" });
  const isScroll = useMediaQuery({ query: "(max-width : 1463px)" });

  const isTablet = useMediaQuery({ query: "(min-width : 850px)" });
  const isMaxTablet = useMediaQuery({ query: "(max-width : 850px)" });

  const isMobile = useMediaQuery({ query: "(min-width: 360px)" });
  const isMaxMobile = useMediaQuery({ query: "(max-width: 360px)" });

  const isMiddleM = useMediaQuery({ query: "(min-width: 701px)" });
  const isMaxMiddleM = useMediaQuery({ query: "(max-width: 700px)" });

  const isMiddleD = useMediaQuery({ query: "(min-width: 1100px)" });
  const isMaxMiddleD = useMediaQuery({ query: "(max-width: 1100px)" });

  const isDesktop = useMediaQuery({ query: "(min-width: 1280px)" });
  const isMaxDesktop = useMediaQuery({ query: "(max-width: 1280px)" });

  return {
    isBanner,
    isTablet,
    isMobile,
    isScroll,
    isDesktop,
    isMiddleD,
    isMiddleM,
    isMaxTablet,
    isMaxMobile,
    isMaxDesktop,
    isMaxMiddleD,
    isMaxMiddleM,
  };
};

export { useMedia };
