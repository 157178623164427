import React from "react";

import { useMedia } from "util/useMedia";
import { dateFormat } from "util/DateUtil";
import { useCommonState } from "context/useContext";

import { TextBox } from "assets/Styles/SText";
import { CutWord } from "assets/Styles/custom/CustomText";
import { Column, Linked, Row } from "assets/Styles/custom/CustomLayout";
import { GrayCon, FlexBox, NotMenuLayout, Empty } from "assets/Styles/SLayout";

import api from "interceptor/api";
import Pagination from "components/hooks/Pagination";
import useGetData from "data/useGetData";
import useFormData from "data/useFormData";
import NoSearch from "components/common/NoSearch";
import SearchCompo from "../../hooks/SearchCompo";
import NoListLayout from "components/common/NoListLayout";
import { SectionTitle } from "components/common/useCommonStyle";
import { t } from "i18next";
import { NoticeIcon } from "assets/components/IconComponent";

const Notice = () => {
  const { isMaxMiddleM } = useMedia();
  const { setLoading } = useCommonState();

  const getNoticeInfo = () => {
    setLoading(true);

    const apiUrl = "/v1/user/notice";

    api
      .get(apiUrl, {
        params: {
          ...getValue.searchValue,
          ...getNotice.extendData.pagingData,
        },
      })
      .then((res) => {
        setLoading(false);
        getNotice.setData(res.data.content);

        if (res.data.pageHelperDto) {
          getNotice.setPagingInfo(res.data.pageHelperDto);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        getNotice.setData([]);
      });
  };

  const getNotice = useGetData({
    firstInit: true,
    api: getNoticeInfo,
    pageSize: "10",
  });

  const getData = getNotice.data;

  const getValue = useFormData(
    {
      value: "",
    },
    getNoticeInfo,
    getNotice
  );

  console.log(getData);

  if (!getData) return;

  return (
    <Column $gap={isMaxMiddleM ? "20px" : "28px"}>
      <SectionTitle name={t("sectionTitle.notice")} />
      <NotMenuLayout>
        <Column $gap="20px">
          <SearchCompo
            page="10"
            name="value"
            getData={getNotice}
            apiFunc={getNoticeInfo}
            placeholder={t("placeholder.search")}
            {...getValue}
          />
          {getData.length !== 0 && (
            <Column $gap="12px">
              {getData.map(({ no, title, create_at }) => (
                <Linked to={`/notice/${no}`} key={no}>
                  <GrayCon $radius="12px" $pad="14px 20px" className="ho-ac">
                    <FlexBox
                      $direct={isMaxMiddleM && "column"}
                      $gap={isMaxMiddleM ? "4px" : "20px"}
                      $jus={!isMaxMiddleM && no !== 0 && "space-between"}
                      $align={isMaxMiddleM ? "flex-start" : "center"}
                    >
                      <Row $gap="12px" $align="center">
                        {no === 0 && (
                          <Empty $width="20px" $height="20px">
                            <NoticeIcon width="20" height="20" fill="var(--c-purple)" />
                          </Empty>
                        )}

                        <CutWord $class={["subB", "white"]}>{title}</CutWord>
                      </Row>
                      {create_at && (
                        <TextBox $white="nowrap" $class={["caption", "gray400"]}>
                          {dateFormat(create_at)}
                        </TextBox>
                      )}
                    </FlexBox>
                  </GrayCon>
                </Linked>
              ))}
            </Column>
          )}
          {getValue.searchValue.value && getData.length === 0 ? <NoSearch /> : getData.length === 0 && <NoListLayout />}
          {getData.length !== 0 && <Pagination {...getNotice} />}
        </Column>
      </NotMenuLayout>
    </Column>
  );
};

export default Notice;
