import React, { useState } from "react";

import { Empty } from "assets/Styles/SLayout";
import { InputCon } from "assets/Styles/SInput";
import { P_GradientBtn } from "assets/Styles/SButton";
import { PasswordIcon } from "assets/components/IconComponent";
import { Between, Column } from "assets/Styles/custom/CustomLayout";

import { InputForm } from "data/useForm";
import { patchRequest } from "apis/patchRequest";
import { getErrorClass } from "util/FormStateUtil";
import { useCommonState } from "context/useContext";

import useFormData from "data/useFormData";
import Error from "components/hooks/Error";
import { t } from "i18next";

const PasswordPopup = ({ openToast, setToastMsg, closePopup }) => {
  const { inputError, setInputError } = useCommonState();

  const [passwordState, setPasswordState] = useState({
    password: { hide: false, type: "password" },
    password2: { hide: false, type: "password" },
    new_password: { hide: false, type: "password" },
  });

  const HidePassword = (type) => {
    setPasswordState((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        hide: !prevState[type].hide,
        type: prevState[type].hide ? "password" : "text",
      },
    }));
  };

  const getValue = useFormData({
    password: "",
    password2: "",
    new_password: "",
  });

  const errorState = () => {
    let hasError = false;

    if (!getValue.formValue.password.length) {
      setInputError((prev) => ({
        ...prev,
        password: t("error.not_match_password"),
      }));
      hasError = true;
    }

    if (!getValue.formValue.password2.length) {
      setInputError((prev) => ({
        ...prev,
        password2: t("error.new_password_mismatch"),
      }));
      hasError = true;
    }

    if (!getValue.formValue.new_password.length) {
      setInputError((prev) => ({
        ...prev,
        new_password: t("error.invalid_password"),
      }));
      hasError = true;
    }

    if (getValue.formValue.new_password !== getValue.formValue.password2) {
      setInputError((prev) => ({
        ...prev,
        password2: t("error.new_password_mismatch"),
      }));
      hasError = true;
    }

    if (
      getValue.formValue.password2 === getValue.formValue.new_password &&
      getValue.formValue.new_password === getValue.formValue.password
    ) {
      setInputError((prev) => ({
        ...prev,
        new_password: t("error.new_password_same"),
      }));
      hasError = true;
    }
    return hasError;
  };

  const errorResponse = (errorCodes) => {
    let errorMessage = "";

    switch (errorCodes) {
      case "M001":
        errorMessage = t("error.not_match_password");
        break;

      default:
        errorMessage = t("error.unknown");
    }

    setInputError((prev) => ({
      ...prev,
      password: errorMessage,
    }));
  };

  const patchAction = () => {
    patchRequest({
      url: "/v1/user/password",
      params: {
        ...getValue.formValue,
      },
      errorState: errorState,
      errorResponse: errorResponse,
      Func: func,
    });
  };

  const func = () => {
    closePopup();
    openToast();
    setToastMsg(t("toast.action_change", { action: t("common.password") }));
  };

  return (
    <>
      <Column $gap="8px" $width="100%" $pad="0 0 20px">
        <Empty>
          <InputCon className={getErrorClass(inputError, "password")} $radius="12px" $pad="12px 16px">
            <Between $gap="12px">
              <InputForm
                height={"20px"}
                type={passwordState.password.type}
                name="password"
                backColor={"var(--c-gray-700)"}
                placeholder={t("placeholder.current_password")}
                errors={inputError}
                setErrors={setInputError}
                {...getValue}
              />
              <PasswordIcon
                onClick={() => HidePassword("password")}
                show={passwordState.password.hide}
                width="20"
                height="20"
                fill="var(--c-gray-300)"
              />
            </Between>
          </InputCon>
          <Error errors={inputError} name={"password"}></Error>
        </Empty>
        <Empty>
          <InputCon className={getErrorClass(inputError, "new_password")} $radius="12px" $pad="12px 16px">
            <Between $gap="12px">
              <InputForm
                height={"20px"}
                type={passwordState.new_password.type}
                name="new_password"
                backColor={"var(--c-gray-700)"}
                placeholder={t("placeholder.new_password")}
                errors={inputError}
                setErrors={setInputError}
                {...getValue}
              />
              <PasswordIcon
                onClick={() => HidePassword("new_password")}
                show={passwordState.new_password.hide}
                width="20"
                height="20"
                fill="var(--c-gray-300)"
              />
            </Between>
          </InputCon>
          <Error errors={inputError} name={"new_password"}></Error>
        </Empty>
        <Empty>
          <InputCon className={getErrorClass(inputError, "password2")} $radius="12px" $pad="12px 16px">
            <Between $gap="12px">
              <InputForm
                height={"20px"}
                type={passwordState.password2.type}
                name="password2"
                backColor={"var(--c-gray-700)"}
                placeholder={t("placeholder.new_password_check")}
                errors={inputError}
                setErrors={setInputError}
                {...getValue}
              />
              <PasswordIcon
                onClick={() => HidePassword("password2")}
                show={passwordState.password2.hide}
                width="20"
                height="20"
                fill="var(--c-gray-300)"
              />
            </Between>
          </InputCon>
          <Error errors={inputError} name={"password2"}></Error>
        </Empty>
      </Column>
      <P_GradientBtn $pad="12px 0" $radius="12px" onClick={patchAction}>
        {t("button.change")}
      </P_GradientBtn>
    </>
  );
};

export default PasswordPopup;
