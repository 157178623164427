import React, { useEffect, useState } from "react";
import styled from "styled-components";
import api from "interceptor/api";

import { Empty } from "assets/Styles/SLayout";
import { TextBox } from "assets/Styles/SText";
import { P_GradientBox } from "assets/Styles/SButton";
import { MoneyIcon, PointIcon } from "assets/components/IconComponent";
import { Table, TableDeps, TableHeader, TableRow } from "assets/Styles/STable";
import { Column, ContentBox, Row, ScrollBox } from "assets/Styles/custom/CustomLayout";

import { useMedia } from "util/useMedia";
import { SelectForm } from "data/useForm";
import { dateCommaFormat, dateFormat } from "util/DateUtil";
import { useCommonState } from "context/useContext";

import useGetData from "data/useGetData";
import useGetEnum from "enum/useGetEnum";
import Pagination from "components/hooks/Pagination";
import NoListLayout from "components/common/NoListLayout";
import { t } from "i18next";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @media (max-width: 1099px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    gap: 12px;
  }
`;

const HistoryDetail = ({ no }) => {
  const enums = useGetEnum();

  const { setLoading } = useCommonState();
  const { isMaxMiddleM, isScroll } = useMedia();

  const [detailData, setDetailData] = useState({});

  const getRecommend = () => {
    setLoading(true);

    const apiUrl = `/v1/refer/info/${no}`;
    api
      .get(apiUrl)
      .then((res) => {
        setDetailData(res.data.content);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCompList = () => {
    const apiUrl = `/v1/refer/history/${no}`;
    api
      .get(apiUrl, {
        params: {
          ...compList.extendData.pagingData,
        },
      })
      .then((res) => {
        compList.setData(res.data.content);
        if (res.data.pageHelperDto) {
          compList.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        compList.setError("");
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        compList.setData([]);
        compList.setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getRecommend();
  }, []);

  const compList = useGetData({
    firstInit: true,
    api: getCompList,
    pageSize: "10",
    select: "type",
    selectData: "DAY",
  });

  if (!compList.data) return;
  return (
    <Column $gap="32px" $overY="auto" $overX="hidden">
      <Column $gap={isMaxMiddleM ? "12px" : "20px"}>
        <ContentBox
          $backColor="var(--c-gray-700)"
          $direct="column"
          $gap="4px"
          $pad="16px"
          $align="center"
          $radius="12px"
        >
          <TextBox $class={["subB", "white"]}>{detailData.nickname}</TextBox>
          <TextBox $class={["caption"]}>
            {t("common.join_date")} {dateFormat(detailData.join_confirm_at)}
          </TextBox>
        </ContentBox>

        <Grid>
          <P_GradientBox $tAlign="left" $radius="12px" $pad={isMaxMiddleM ? "14px 16px" : "14px 20px"}>
            <Row $gap="3px" $align="center">
              <TextBox $class={["white", isMaxMiddleM ? "captionB" : "subB"]}>
                {t("infoMsg.today_betting_money")}
              </TextBox>
              <MoneyIcon width={isMaxMiddleM ? "16" : "20"} height={isMaxMiddleM ? "16" : "20"} fill="var(--c-white)" />
            </Row>
            <TextBox $class={["white", isMaxMiddleM ? "title" : "header"]} $pad={!isMaxMiddleM && "4px 0 0"}>
              {detailData.today_bet_money?.toLocaleString()}
            </TextBox>
          </P_GradientBox>
          <P_GradientBox $tAlign="left" $radius="12px" $pad={isMaxMiddleM ? "14px 16px" : "14px 20px"}>
            <Row $gap="3px" $align="center">
              <TextBox $class={["white", isMaxMiddleM ? "captionB" : "subB"]}>
                {t("infoMsg.total_betting_money")}
              </TextBox>
              <MoneyIcon width={isMaxMiddleM ? "16" : "20"} height={isMaxMiddleM ? "16" : "20"} fill="var(--c-white)" />
            </Row>
            <TextBox $class={["white", isMaxMiddleM ? "title" : "header"]} $pad={!isMaxMiddleM && "4px 0 0"}>
              {detailData.total_game_bet_money?.toLocaleString()}
            </TextBox>
          </P_GradientBox>
          <P_GradientBox $tAlign="left" $radius="12px" $pad={isMaxMiddleM ? "14px 16px" : "14px 20px"}>
            <Row $gap="3px" $align="center">
              <TextBox $class={["white", isMaxMiddleM ? "captionB" : "subB"]}>{t("infoMsg.today_comp")}</TextBox>
              <PointIcon width={isMaxMiddleM ? "16" : "20"} height={isMaxMiddleM ? "16" : "20"} fill="var(--c-white)" />
            </Row>
            <TextBox $class={["white", isMaxMiddleM ? "title" : "header"]} $pad={!isMaxMiddleM && "4px 0 0"}>
              {detailData.today_user_comp?.toLocaleString()}
            </TextBox>
          </P_GradientBox>
          <P_GradientBox $tAlign="left" $radius="12px" $pad={isMaxMiddleM ? "14px 16px" : "14px 20px"}>
            <Row $gap="3px" $align="center">
              <TextBox $class={["white", isMaxMiddleM ? "captionB" : "subB"]}>{t("infoMsg.total_comp")}</TextBox>
              <PointIcon width={isMaxMiddleM ? "16" : "20"} height={isMaxMiddleM ? "16" : "20"} fill="var(--c-white)" />
            </Row>
            <TextBox $class={["white", isMaxMiddleM ? "title" : "header"]} $pad={!isMaxMiddleM && "4px 0 0"}>
              {detailData.total_user_comp?.toLocaleString()}
            </TextBox>
          </P_GradientBox>
        </Grid>
      </Column>
      <Column $gap="16px">
        <TextBox $class={["subB", "white"]}>{t("sectionTitle.comp_history")}</TextBox>
        <SelectForm
          pad="10px 20px"
          name="type"
          options={enums.compState}
          conWidth={isMaxMiddleM && "100%"}
          placeholder={t("select.all_member_grade")}
          {...compList}
        />
        {compList?.data?.length === 0 ? (
          <>
            <NoListLayout />
          </>
        ) : (
          <>
            <ScrollBox $pad={"20px"} $radius="12px" $backColor="var(--c-gray-700)">
              <Table className="table-box" $tLayout="fixed" $width="100%">
                <thead>
                  <tr className="table-title">
                    <TableHeader $minWidth={isScroll && "176px"} $width={isScroll && "176px"}>
                      No
                    </TableHeader>

                    <TableHeader $width={isScroll && "176px"} $minWidth={isScroll && "176px"}>
                      {t("table.occurrence_date")}
                    </TableHeader>

                    <TableHeader $minWidth={isScroll && "176px"} $width={isScroll && "176px"}>
                      <Row $gap="4px" $jus="center">
                        <TextBox>{t("table.betting_money")}</TextBox>
                        <Empty $width="20px" $height="20px">
                          <MoneyIcon width="20" height="20" />
                        </Empty>
                      </Row>
                    </TableHeader>

                    <TableHeader $minWidth={isScroll && "176px"} $width={isScroll && "176px"}>
                      <Row $gap="4px" $jus="center">
                        <TextBox>{t("table.recommend_rolling_comp")}</TextBox>
                        <Empty $width="20px" $height="20px">
                          <PointIcon width="20" height="20" />
                        </Empty>
                      </Row>
                    </TableHeader>

                    <TableHeader $minWidth={isScroll && "176px"} $width={isScroll && "176px"}>
                      <Row $gap="4px" $jus="center">
                        <TextBox>{t("table.recommend_losing_comp")}</TextBox>
                        <Empty $width="20px" $height="20px">
                          <PointIcon width="20" height="20" />
                        </Empty>
                      </Row>
                    </TableHeader>

                    <TableHeader $minWidth={isScroll && "176px"} $width={isScroll && "176px"}>
                      <Row $gap="4px" $jus="center">
                        <TextBox>{t("table.recommend_total_comp")}</TextBox>
                        <Empty $width="20px" $height="20px">
                          <PointIcon width="20" height="20" />
                        </Empty>
                      </Row>
                    </TableHeader>
                  </tr>
                </thead>
                <tbody>
                  {compList.data &&
                    compList.data?.map(
                      ({ bet_money, recommend_comp, recommend_losing, recommend_rolling, yyyymmdd, yyyymm }, index) => {
                        const number = compList.pagingInfo.offset - index;

                        return (
                          <TableRow className="table-content" key={number}>
                            <TableDeps $white={"wrap"}>{number}</TableDeps>
                            <TableDeps $white={"wrap"}>{dateCommaFormat(yyyymmdd || yyyymm)}</TableDeps>
                            <TableDeps $white={"wrap"}>{bet_money?.toLocaleString()}</TableDeps>
                            <TableDeps $white={"wrap"}>{recommend_comp?.toLocaleString()}</TableDeps>
                            <TableDeps $white={"wrap"}>{recommend_losing?.toLocaleString()}</TableDeps>
                            <TableDeps $white={"wrap"}>{recommend_rolling?.toLocaleString()}</TableDeps>
                          </TableRow>
                        );
                      }
                    )}
                </tbody>
              </Table>
            </ScrollBox>
            {compList.data?.length !== 0 && <Pagination {...compList} />}
          </>
        )}
      </Column>
    </Column>
  );
};

export default HistoryDetail;
