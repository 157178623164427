import React from 'react';
import { HamburgerIcon } from 'assets/components/IconComponent';
import { MobileHamburger } from 'assets/Styles/componentstyle/HeaderStyle';

const MHeader = ({ menuToggle, menuSlide }) => {
  return (
    <MobileHamburger onClick={menuToggle}>
      <HamburgerIcon
        className={menuSlide && 'active'}
        width="16"
        height="16"
      />
    </MobileHamburger>
  );
};

export default MHeader;
