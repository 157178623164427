import { useLocation, Navigate } from "react-router-dom";
import queryString from "query-string";
import { useCommonState } from "context/useContext";

function Oauth() {
  const { search } = useLocation();
  const { accessToken, refreshToken } = queryString.parse(search);
  const { setIsLogin } = useCommonState();

  if (accessToken && refreshToken) {
    localStorage.setItem("access-token", accessToken);
    localStorage.setItem("refresh-token", refreshToken);
    setIsLogin(true);
  } else {
    localStorage.removeItem("access-token");
    localStorage.removeItem("refresh-token");

    setIsLogin(false);

    return <Navigate to="/" />;
  }
  return <Navigate to="/" />;
}

export default Oauth;
