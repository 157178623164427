import { useNavigate } from "react-router-dom";
import Login from "components/popup/Login";
import { useAlert, usePopup } from "context/useWindow";
import { useCommonState } from "context/useContext";
import { t } from "i18next";

export const useStateCheck = () => {
  const navigate = useNavigate();
  const { openAlert } = useAlert();
  const { openPopup } = usePopup();
  const { isLogin, userState } = useCommonState();

  const showPopup = () => {
    openPopup({
      content: Login,
      title: t("sectionTitle.login"),
    });
  };

  const showAlert = () => {
    openAlert({
      title: t("alert.join_wait"),
      message: t("alert.join_wait_message"),
    });
  };

  const stateCheck = (url) => {
    if (!isLogin) {
      showPopup();
    } else if (url === "/customer") {
      navigate(url);
    } else if (userState === "W") {
      showAlert();
    } else {
      if (url.startsWith("http://") || url.startsWith("https://")) {
        window.open(url, "_blank");
      } else {
        navigate(url);
      }
    }
  };

  return stateCheck;
};
