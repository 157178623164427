import axios from "axios";
import i18n from "locales/i18n";

import { postRequest } from "apis/postRequest";
import { errorFlagEnum } from "enum/useCommonEnum";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

/**
 1. 요청 인터셉터
 2개의 콜백 함수를 받습니다.
 */
api.interceptors.request.use(
  (config) => {
    // HTTP Authorization 요청 헤더에 jwt-token을 넣음
    // 서버측 미들웨어에서 이를 확인하고 검증한 후 해당 API에 요청함.

    const accessToken = localStorage.getItem("access-token");
    try {
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    } catch (err) {
      console.error("[_axios.interceptors.request] config : " + err);
    }
    return config;
  },
  (error) => {
    // 요청 에러 직전 호출됩니다.
    return Promise.reject(error);
  }
);

/**
 2. 응답 인터셉터
 2개의 콜백 함수를 받습니다.
 */
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      const errorStatueCode = error.response.status;
      switch (errorStatueCode) {
        case 401:
          if (error.response?.data?.error_flag === errorFlagEnum.LOGOUT) {
            postRequest({
              url: "/v1/user/logout",
              body: {},
            });

            window.location.reload();
            window.location.href = "/";
            localStorage.removeItem("access-token");
            localStorage.removeItem("refresh-token");

            window.location.href = "/";
            alert(i18n.t("alert.duplicate_login"));

            return Promise.reject(error);
          }
          // 토큰 갱신 시도
          const authRefreshToken = localStorage.getItem("refresh-token");
          if (authRefreshToken) {
            try {
              const refreshResponse = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v1/auth/refresh-token`,
                {},
                {
                  headers: {
                    Authorization: `Bearer ${authRefreshToken}`,
                  },
                }
              );

              const { accessToken, refreshToken } = refreshResponse.data.content;
              localStorage.removeItem("access-token");
              localStorage.removeItem("refresh-token");
              localStorage.setItem("access-token", accessToken);
              localStorage.setItem("refresh-token", refreshToken);
              // 리턴 재시도
              return api(error.config);
            } catch (err) {
              localStorage.removeItem("access-token");
              localStorage.removeItem("refresh-token");
              window.location.href = "/";

              // 토큰 갱신 실패 시 로그아웃 처리
              return Promise.reject(err);
            }
          }
          break;

        case 403:
          localStorage.removeItem("access-token");
          localStorage.removeItem("refresh-token");
          window.location.href = "/";

          return Promise.reject(error);

        default:
          return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default api;
