import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";

import MyMenu from "../layout/MyMenu";

import { useMedia } from "util/useMedia";
import { useCommonState } from "context/useContext";

import { TextBox } from "assets/Styles/SText";
import { Between, Column, Row } from "assets/Styles/custom/CustomLayout";
import { BorderBtn } from "assets/Styles/SButton";
import { GrayCon, FlexBox, MyPageLayout, LineSection } from "assets/Styles/SLayout";

import api from "interceptor/api";
import useGetData from "data/useGetData";
import Pagination from "components/hooks/Pagination";
import NoListLayout from "components/common/NoListLayout";

import { CheckForm } from "data/useForm";
import { useConfirm } from "context/useWindow";
import { dateCommaFormat } from "util/DateUtil";
import { patchRequest } from "apis/patchRequest";
import { CheckInput } from "assets/Styles/SInput";

const MyNote = () => {
  const navigate = useNavigate();

  const { isMiddleD } = useMedia();
  const { pathname } = useLocation();
  const { openConfirm } = useConfirm();
  const { setLoading, eventSource, setEventSource } = useCommonState();

  const [allNote, setAllNote] = useState({});
  const [readNote, setReadNote] = useState({});

  const [unreadList, setUnreadList] = useState(false);

  const getUserArr = () => {
    return (getNote.data || []).filter((item) => item.isChecked).map((item) => ({ no: item.no }));
  };

  const getNoteInfo = () => {
    setLoading(true);

    const apiUrl = "/v1/user/messages";

    api
      .get(apiUrl, {
        params: {
          ...getNote.extendData.pagingData,
        },
      })
      .then((res) => {
        setLoading(false);
        getNote.setData(res.data.content);
        setAllNote(res.data.total_messages);
        setReadNote(res.data.total_unread_messages);

        if (res.data.pageHelperDto) {
          getNote.setPagingInfo(res.data.pageHelperDto);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        getNote.setData([]);
      });
  };

  const getNote = useGetData({
    firstInit: true,
    api: getNoteInfo,
    pageSize: "10",
  });

  const getData = getNote.data;

  const listTypeClick = (type) => {
    setUnreadList(!unreadList);

    getNote.setExtendData({
      ...getNote.extendData,
      pagingData: {
        ...getNote.extendData.pagingData,
        page: "1",
        type: type,
      },
    });
  };

  const readFunc = () => {
    patchRequest({
      url: "/v1/user/messages/read",
      params: {
        message_list: getUserArr().map((item) => item.no),
      },
      Func: getNoteInfo,
    });
  };

  const confirmFunc = () => {
    patchRequest({
      url: "/v1/user/messages/delete",
      params: {
        message_list: getUserArr().map((item) => item.no),
      },
      Func: getNoteInfo,
    });
  };

  const deleteConfirm = () => {
    openConfirm({
      title: t("alert.note_delete"),
      message: t("alert.note_delete_message"),
      isRed: true,
      subBtn: t("button.cancel"),
      Func: confirmFunc,
    });
  };

  useEffect(() => {
    if (eventSource.is_alarm === 1) {
      setEventSource({ ...eventSource, is_alarm: 0 });
      patchRequest({
        url: "/v1/user/messages/alarm-check",
      });
    }
  }, [pathname, eventSource]);

  useEffect(() => {
    if (readFunc) {
      setEventSource({ ...eventSource, total_unread_messages: readNote });
    }
  }, [readNote]);

  const moveNote = (no) => {
    const newReadNote = Math.max(0, readNote - 1);
    setEventSource({ ...eventSource, total_unread_messages: newReadNote });

    navigate(`/note/${no}`);
  };

  const selectedItems = getUserArr();
  const isSelectable = selectedItems?.length === 0;
  const allRead = getData?.length > 0 && getData?.every((item) => item?.state === "READ");

  if (!getData) return;

  return (
    <FlexBox $gap={isMiddleD && "28px"} $direct={isMiddleD ? "row" : "column"}>
      <MyMenu />

      <MyPageLayout>
        <Column $gap="16px">
          <Row $gap="8px">
            <TextBox $class={["title", "white"]}>{t("common.all_note")}</TextBox>
            <Row $gap="4px">
              <TextBox $class={["title", "purple"]}>{readNote}</TextBox>
              <TextBox $class={["title", "white"]}>/</TextBox>
              <TextBox $class={["title", "white"]}>{allNote}</TextBox>
            </Row>
          </Row>

          <LineSection $borB="1px solid var(--c-gray-600)" $width="100%" />

          <Between>
            <Row $gap="12px">
              <BorderBtn $pad="8.5px" $dis="flex" $backColor="var(--c-gray-700)" $bor="1px solid var(--c-gray-600)">
                <CheckForm name="all" base={getNote} />
              </BorderBtn>
              <BorderBtn
                $backColor="var(--c-gray-700)"
                $bor="1px solid var(--c-gray-600)"
                onClick={readFunc}
                disabled={isSelectable}
              >
                <TextBox $class={["subB", "white"]}>{t("button.read")}</TextBox>
              </BorderBtn>
              <BorderBtn
                $backColor="var(--c-gray-700)"
                $bor="1px solid var(--c-gray-600)"
                onClick={deleteConfirm}
                disabled={isSelectable}
              >
                <TextBox $class={["subB", "red"]}>{t("button.delete")}</TextBox>
              </BorderBtn>
            </Row>
            <Row $gap="6px" $pad="0 3px" $align="center">
              <CheckInput
                name="type"
                id="un-read"
                disabled={allRead}
                checked={unreadList}
                onChange={() => listTypeClick(!unreadList ? "UNREAD" : "")}
              />
              <TextBox as="label" htmlFor="un-read" $class={["white"]}>
                {t("button.not_read_note")}
              </TextBox>
            </Row>
          </Between>

          {getData.length !== 0 && (
            <Column $gap="12px">
              {getData.map(({ no, title, state, create_at, ...rest }) => {
                let readStyle = [];

                switch (state) {
                  case "UNREAD":
                    readStyle = ["subB", "white"];
                    break;
                  case "READ":
                    readStyle = ["subB", "gray400"];
                    break;
                  default:
                    readStyle = ["subB", "white"];
                    break;
                }

                return (
                  <GrayCon key={no} $radius="12px" $pad="14px 20px">
                    <Between $gap="16px">
                      <Row $gap="10px" $width="100%" $align="center">
                        <CheckForm keys={no} base={getNote} eachData={{ no, ...rest }} />
                        <TextBox onClick={() => moveNote(no)} className="ho-un" $class={readStyle} $width="100%">
                          {title}
                        </TextBox>
                      </Row>
                      <TextBox $class={["caption", "gray300"]} $white="nowrap">
                        {dateCommaFormat(create_at)}
                      </TextBox>
                    </Between>
                  </GrayCon>
                );
              })}
            </Column>
          )}

          {getData.length === 0 && <NoListLayout />}

          {getData.length !== 0 && <Pagination {...getNote} />}
        </Column>
      </MyPageLayout>
    </FlexBox>
  );
};

export default MyNote;
