import React from "react";

import { InputCon } from "assets/Styles/SInput";
import { TextBox, WarningMsg } from "assets/Styles/SText";
import { Between, Column, Row } from "assets/Styles/custom/CustomLayout";
import { CheckIcon, MoneyIcon, ResetIcon, WarningIcon } from "assets/components/IconComponent";
import { CashPlusBtn, ContentSwap, P_GradientBtn, ResetBtn } from "assets/Styles/SButton";
import { GrayCon, Empty, FlexBox, LineSection, MyPageLayout, WarningBox } from "assets/Styles/SLayout";

import { InputForm } from "data/useForm";
import { useMedia } from "util/useMedia";
import { usePopup } from "context/useWindow";

import MyMenu from "../layout/MyMenu";
import useFormData from "data/useFormData";
import WithdrawPopup from "components/popup/WithdrawPopup";
import { useCommonState } from "context/useContext";
import api from "interceptor/api";
import useToast from "components/hooks/useToast";
import { valueReplace } from "util/Inputmask";
import useGetData from "data/useGetData";
import { getErrorClass } from "util/FormStateUtil";
import Error from "components/hooks/Error";
import { t } from "i18next";

const Withdraw = () => {
  const { isMiddleD, isMaxMiddleM } = useMedia();
  const { openPopup } = usePopup();
  const { setLoading, inputError, setInputError } = useCommonState();
  const { toastOpen, openToast, Toast } = useToast();

  const getAccountInfo = () => {
    setLoading(true);

    const apiUrl = "/v1/account/out";

    api
      .get(apiUrl)
      .then((res) => {
        getAccount.setData(res.data.content);
        setLoading(false);
        getAccount.setError("");
      })
      .catch((err) => {
        console.error(err);
        getAccount.setError(true);
        setLoading(false);
        getAccount.setData([]);
      });
  };

  const withdrawApp = () => {
    const minMoney = getData.account_out_min_money;
    const maxMoney = getData.account_out_max_money;

    const formValued = getValue.formValue;

    const cashValue = formValued.amount ? valueReplace(formValued.amount.toString()) : 0;

    if (cashValue >= minMoney && cashValue <= maxMoney && getData.user_money >= cashValue) {
      showPopup();
    } else {
      if (getData.user_money < cashValue) {
        setInputError({
          amount: t("error.impossible_withdraw"),
        });
      } else {
        setInputError({
          amount: t("error.min_max_withdraw"),
        });
      }
    }
  };

  const getAccount = useGetData({
    firstInit: true,
    api: getAccountInfo,
  });

  const getData = getAccount.data;
  const getValue = useFormData({
    amount: "",
  });

  const cashPlus = (money) => {
    getValue.setFormValue((prev) => {
      let currentCash = typeof prev.amount === "string" ? parseInt(prev.amount.replace(/,/g, ""), 10) : prev.amount;

      if (isNaN(currentCash)) {
        currentCash = 0;
      }

      const updatedCash = currentCash + money;
      return {
        ...prev,
        amount: updatedCash.toString(),
      };
    });
  };

  const showPopup = () => {
    openPopup({
      content: WithdrawPopup,
      title: t("common.withdraw_password"),
      props: {
        amount: getValue.formValue.amount,
        openToast: openToast,
      },
    });
  };
  if (!getData) return;

  return (
    <FlexBox $gap={isMiddleD && "28px"} $direct={isMiddleD ? "row" : "column"}>
      <MyMenu />

      <MyPageLayout>
        <Column $gap="24px">
          <Row $pad="6px" $radius="30px" $backColor="var(--c-gray-900)">
            <ContentSwap className="active">{t("common.withdraw")}</ContentSwap>
            <ContentSwap href="/my.withdraw">{t("sectionTitle.withdraw_history")}</ContentSwap>
          </Row>

          {getData?.current_time_in_range === "N" && !getData?.isExistInAccountHistory ? (
            <>
              <Column $gap="10px">
                <InputCon $pad="12px 16px" $radius="12px">
                  <Between>
                    <TextBox $class="gray300">{t("common.balance")}</TextBox>
                    <Row $gap="6px">
                      <TextBox $class={["subB", "gray300"]}>{getData.user_money?.toLocaleString()}</TextBox>
                      <MoneyIcon width="20" height="20" />
                    </Row>
                  </Between>
                </InputCon>
                <InputCon $pad="12px 16px" $radius="12px" className={getErrorClass(inputError, "amount")}>
                  <Between>
                    <TextBox $class="gray300" $white="nowrap">
                      {t("common.withdraw_amount")}
                    </TextBox>
                    <Row $gap="6px" $width="100%">
                      <InputForm
                        name="amount"
                        tAlign="right"
                        placeholder="0"
                        font="var(--f-subB)"
                        maskType="comma_number"
                        placeColor="var(--c-white)"
                        backColor="var(--c-gray-700)"
                        errors={inputError}
                        setErrors={setInputError}
                        {...getValue}
                      />
                      <Empty $width="20px" $height="20px">
                        <MoneyIcon width="20" height="20" />
                      </Empty>
                    </Row>
                  </Between>
                </InputCon>
                <Row $gap="10px">
                  <CashPlusBtn onClick={() => cashPlus(10000)}>+10,000</CashPlusBtn>
                  <CashPlusBtn onClick={() => cashPlus(100000)}>+100,000</CashPlusBtn>
                  <CashPlusBtn onClick={() => cashPlus(1000000)}>+1,000,000</CashPlusBtn>
                  <ResetBtn
                    onClick={() => {
                      getValue.resetForm();
                      setInputError({});
                    }}
                  >
                    <ResetIcon width="20" height="20" />
                  </ResetBtn>
                </Row>
                <Error name={"amount"} errors={inputError}></Error>

                <TextBox $class={isMaxMiddleM ? ["caption", "gray300"] : "gray300"}>
                  {t("infoMsg.amount_range", {
                    action: t("common.withdraw"),
                    min: getData?.account_out_min_money?.toLocaleString(),
                    max: getData?.account_out_max_money?.toLocaleString(),
                  })}
                </TextBox>
              </Column>

              <Column $gap="10px">
                <GrayCon>
                  <Between>
                    <TextBox $class="gray300">{t("common.bank")}</TextBox>
                    <TextBox $class="white">{getData?.account_bank}</TextBox>
                  </Between>
                  <Between>
                    <TextBox $class="gray300">{t("common.account_num")}</TextBox>
                    <TextBox $class="white">{getData?.account_no}</TextBox>
                  </Between>
                  <Between>
                    <TextBox $class="gray300">{t("common.account_name")}</TextBox>
                    <TextBox $class="white">{getData?.account_name}</TextBox>
                  </Between>
                </GrayCon>
                <TextBox $class={isMaxMiddleM ? ["caption", "gray300"] : "gray300"}>
                  {t("infoMsg.amount_message", {
                    action: t("common.withdraw"),
                  })}
                </TextBox>
              </Column>

              <LineSection $borB="1px solid var(--c-gray-600)" $width="100%" />

              <WarningBox $pad="14px 20px" $radius="12px">
                <Column $gap="12px">
                  {getData.account_out_limit_start_time &&
                    getData.account_out_limit_end_time &&
                    getData?.account_out_limit_time_yn === "Y" && (
                      <Row $gap="8px" $align="flex-start">
                        <Row $width="18px" $height="18px">
                          <WarningIcon width="18" height="18" />
                        </Row>
                        <WarningMsg $font="var(--f-subB)" $size="var(--s-sub)" $padT="0">
                          {t("infoMsg.inspection_time_message_detail", {
                            days: getData?.account_out_limit_days_text,
                            startTime: getData?.account_out_limit_start_time,
                            endTime: getData?.account_out_limit_end_time,
                            action: t("common.withdraw"),
                          })}
                        </WarningMsg>
                      </Row>
                    )}
                  <Row $gap="8px" $align="flex-start">
                    <Row $width="18px" $height="18px">
                      <WarningIcon width="18" height="18" />
                    </Row>
                    <WarningMsg $font="var(--f-subB)" $size="var(--s-sub)" $padT="0">
                      {t("infoMsg.request_message", {
                        action: t("common.withdraw"),
                        wait: getData?.account_out_wait,
                        reWait: getData?.account_out_re_wait,
                      })}
                    </WarningMsg>
                  </Row>
                </Column>
              </WarningBox>

              <P_GradientBtn onClick={withdrawApp} $pad="12px 0" $width="100%" $radius="12px">
                {t("button.request", {
                  action: t("common.withdraw", { ns: "translation" }),
                })}
              </P_GradientBtn>
            </>
          ) : (
            <GrayCon>
              <Column $gap="8px" $align="center">
                <WarningIcon width="24" height="24" />
                {getData.isExistInAccountHistory ? (
                  <Empty>
                    <TextBox $width="100%" $tAlign="center" $class={["white", "title"]}>
                      {t("infoMsg.request_in_progress")}
                    </TextBox>
                    <TextBox $width="100%" $tAlign="center" $class={["white", "title"]}>
                      {t("infoMsg.request_wait_message", {
                        wait: getData?.account_out_wait,
                      })}
                    </TextBox>
                  </Empty>
                ) : (
                  <>
                    <TextBox $class={["white", "title"]}>
                      {t("infoMsg.inspection_time_message", {
                        action: t("common.withdraw"),
                      })}
                    </TextBox>
                    <TextBox $class="subB">
                      {`${t("infoMsg.inspection_time")} : ${getData?.account_out_limit_days_text} ${
                        getData?.account_out_limit_start_time
                      }~${getData?.account_out_limit_end_time}`}
                    </TextBox>
                  </>
                )}
              </Column>
            </GrayCon>
          )}
        </Column>
        {toastOpen && (
          <Toast Icon={<CheckIcon />} toastText={t("toast.request_success", { action: t("common.withdraw") })} />
        )}
      </MyPageLayout>
    </FlexBox>
  );
};

export default Withdraw;
