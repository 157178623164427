import styled, { css } from "styled-components";
import { textStyles } from "constant/useClass";

const createTextBox = ({
  $dis,
  $color,
  $weight,
  $tAlign,
  $white,
  $width,
  $minWidth,
  $op,
  $over,
  $textDeco,
  $pad,
  $radius,
  $mask,
  $border,
  $shadow,
  $mar,
  $class,
  $cursor,
  $backColor,
}) => {
  const classes = Array.isArray($class) ? $class : [$class];

  // 각 클래스에 해당하는 스타일을 합치기
  const combinedStyles = classes.map((cls) => textStyles[cls]).join(" ");
  return css`
    color: ${$color};

    font-weight: ${$weight};

    width: ${$width};
    min-width: ${$minWidth};

    text-align: ${$tAlign};
    white-space: ${$white};
    opacity: ${$op};
    overflow: ${$over};
    text-decoration: ${$textDeco};

    margin: ${$mar};
    padding: ${$pad};
    border-radius: ${$radius};
    background-color: ${$backColor};
    border: ${$border};
    cursor: ${$cursor};

    mask-image: ${$mask};
    -webkit-mask-image: ${$mask};
    box-shadow: ${$shadow};
    word-break: break-all;
    display: ${$dis || "inline-block"};

    &.ho-un {
      &:hover {
        text-decoration: underline;
      }
    }

    ${combinedStyles}
  `;
};

const createWarningMsg = ({ $padT, $top, $size, $font, $line, $position }) => css`
  font-size: ${$size ? $size : " var(--s-caption)"};
  font-family: ${$font ? $font : " var(--f-caption)"};
  line-height: ${$line ? $line : " var(--l-caption)"};

  top: ${$top};
  position: ${$position};
  color: var(--c-red);
  display: inline-block;
  padding-top: ${$padT ?? "8px"};
`;

const createSuccessMsg = ({ $mar, $top, $size, $font, $line, $position }) => css`
  font-size: ${$size ? $size : " var(--s-caption)"};
  font-family: ${$font ? $font : " var(--f-caption)"};
  line-height: ${$line ? $line : " var(--l-caption)"};
  margin: ${$mar};
  top: ${$top};
  display: inline-block;
  position: ${$position};
  color: var(--c-blue);
`;

const TextBox = styled.span`
  ${createTextBox}
`;

const WarningMsg = styled.span.attrs({ className: "error" })`
  ${createWarningMsg}
`;

const SuccessMsg = styled.span.attrs({ className: "Success" })`
  ${createSuccessMsg}
`;

const Label = styled.label`
  white-space: nowrap;
  color: var(--c-gray-900);
  font-size: var(--s-sub);
  line-height: var(--l-sub);
`;

const createA = ({
  $dis,
  $color,
  $weight,
  $tAlign,
  $white,
  $width,
  $minWidth,
  $op,
  $over,
  $textDeco,
  $pad,
  $radius,
  $mask,
  $border,
  $shadow,
  $mar,
  $class,
  $cursor,
}) => {
  const classes = Array.isArray($class) ? $class : [$class];

  // 각 클래스에 해당하는 스타일을 합치기
  const combinedStyles = classes.map((cls) => textStyles[cls]).join(" ");
  return css`
    color: ${$color};

    font-weight: ${$weight};

    width: ${$width};
    min-width: ${$minWidth};

    text-align: ${$tAlign};
    white-space: ${$white};
    opacity: ${$op};
    overflow: ${$over};
    text-decoration: ${$textDeco};

    margin: ${$mar};
    padding: ${$pad};
    border-radius: ${$radius};
    border: ${$border};
    cursor: ${$cursor};

    mask-image: ${$mask};
    -webkit-mask-image: ${$mask};
    box-shadow: ${$shadow};
    display: ${$dis || "inline-block"};
    ${combinedStyles}
  `;
};

const A = styled.a`
  ${createA}
`;

const RequiredText = styled(TextBox)`
  position: relative;
  width: fit-content;

  color: var(--c-white);
  font-size: var(--s-caption);

  padding-bottom: 8px;

  &::after {
    content: "";
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 999px;
    top: 0px;
    right: -10px;
    background-color: var(--c-red);
  }
`;

export { A, Label, TextBox, WarningMsg, SuccessMsg, RequiredText };
