import React from "react";
import styled from "styled-components";
import { TextBox } from "assets/Styles/SText";
import { Column, Row } from "assets/Styles/custom/CustomLayout";
import { ActionBtn } from "assets/Styles/SButton";
import { t } from "i18next";

const Progress = styled.div`
  width: 60px;
  height: 6px;
  border-radius: 30px;
  background-color: var(--c-gray-600);
`;
const Dealt = styled.div`
  width: ${(props) => props.$width}%;
  border-radius: 30px;
  background-color: var(--c-purple);
  height: 6px;
`;

const MorePaging = ({ data, morePage, pagingInfo, num = 0, totalNum = 0 }) => {
  if (!num) return (num = 0);

  const stringNum = Number(num);
  const stringTotalNum = Number(totalNum);

  if (!data) return null;
  return (
    <Column $jus="center" $align="center">
      <Row $gap="12px" $align="center">
        <TextBox>
          {num} &nbsp;/&nbsp; {totalNum}
        </TextBox>
        <Progress>
          <Dealt $width={(stringNum / stringTotalNum) * 100} />
        </Progress>
      </Row>
      {num === pagingInfo.offset ? null : (
        <ActionBtn
          onClick={() => morePage(pagingInfo?.pageSize + 32)}
          $mar="12px 0 0"
          $pad="10px 20px"
          $width="fit-content"
          $radius="999px"
        >
          {t("button.more")}
        </ActionBtn>
      )}
    </Column>
  );
};

export default MorePaging;
