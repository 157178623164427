import React, { useState } from "react";
import styled from "styled-components";
import { format } from "date-fns";

import { TextBox } from "assets/Styles/SText";
import { Row } from "assets/Styles/custom/CustomLayout";
import { MonthIcon, YearIcon } from "assets/components/IconComponent";

const MonthConstant = [
  { id: 1, name: "1월" },
  { id: 2, name: "2월" },
  { id: 3, name: "3월" },
  { id: 4, name: "4월" },
  { id: 5, name: "5월" },
  { id: 6, name: "6월" },
  { id: 7, name: "7월" },
  { id: 8, name: "8월" },
  { id: 9, name: "9월" },
  { id: 10, name: "10월" },
  { id: 11, name: "11월" },
  { id: 12, name: "12월" },
];

const Table = styled.table`
  border-spacing: 0;
  padding: 20px;
`;

const WhiteLayoutBox = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  background-color: var(--c-gray-700);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);

  width: fit-content;
  user-select: none;

  margin-top: 8px;

  border-radius: 12px;

  @media (max-width: 700px) {
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-width: 320px) {
    left: 50%;
    transform: translateX(-50%);
  }
`;

const TableHeader = styled.th`
  font-family: var(--f-subB);
  font-size: var(--s-sub);
  color: var(--c-black-op);
  width: 40px;
  height: 40px;
  vertical-align: middle;
  border: none;

  text-align: center;

  &.sunday {
    color: var(--c-red);
  }
  &.saturday {
    color: var(--c-blue);
  }
`;

const CalendarDayBox = styled.td`
  font-family: var(--f-text);
  font-size: var(--s-text);
  line-height: var(--l-text);
  color: var(--c-gray-900);

  vertical-align: middle;
  border: none;
  text-align: center;

  cursor: pointer;

  &.impossible_select:hover {
    background-color: transparent;
  }

  &.impossible_select div {
    color: var(--c-gray-500);
    cursor: initial;
  }

  &.in-range {
    background: var(--c-gray-600);
  }
  &.in-range.start-date {
    border-radius: 8px 0 0 8px;
  }
  &.in-range.end-date {
    border-radius: 0 8px 8px 0;
  }
`;

const Day = styled.div`
  font-family: var(--f-text);
  font-size: var(--s-text);
  line-height: var(--l-text);

  width: 40px;
  height: 40px;
  min-width: 40px;

  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  &.in-range {
    background-color: var(--c-gray-600);
    width: 100%;
    height: 100%;
  }

  &.start-date,
  &.end-date {
    background-color: var(--c-purple);
    color: var(--c-gray-700);
    border-radius: 8px !important;
  }
`;

const MonthMove = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;

  &.next-month {
    rotate: 180deg;
  }
  &.prev-month {
    rotate: 0deg;
  }
  &.not-found-day:hover {
    background-color: transparent;
  }
  &.not-found-day {
    cursor: initial;

    opacity: 0.3;
  }
`;

const YearMove = styled.div`
  background: url(/assets/svg/year_move.svg);
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:hover {
    background-color: var(--c-input);
    border-radius: 4px;
  }

  &.prev-year {
    rotate: 0deg;
  }
  &.next-year {
    rotate: 180deg;
  }

  &.not-found-day:hover {
    background-color: transparent;
  }

  &.not-found-day {
    cursor: initial;
    opacity: 0.3;
  }
`;

const Calendar = ({ endDate, dateType, onToggle, startDate, setStartDate, setEndDate }) => {
  const currentDate = new Date();
  const defaultDate = new Date(2024, 0, 1);
  const [today, setToday] = useState(currentDate);

  const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());
  const prevMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
  const nextYear = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
  const prevYear = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());

  const monthToggle = (newDate) => {
    if (newDate <= currentDate) {
      setToday(newDate);
    }
  };

  const yearToggle = (newDate) => {
    if (newDate <= currentDate) {
      setToday(newDate);
    }
  };

  const nextYearToggle = () => {
    if (nextYear <= currentDate) {
      yearToggle(nextYear);
    } else {
      yearToggle(currentDate);
    }
  };

  const prevYearToggle = () => yearToggle(prevYear);
  const nextMonthToggle = () => monthToggle(nextMonth);
  const prevMonthToggle = () => monthToggle(prevMonth);

  // 연월일 시간 비교 같은 날짜 클릭 시 클래스 삭제
  const isSameDate = (clickedDate, selectDate) => clickedDate === selectDate;

  const isDisabledDate = (clickedDate) => {
    return clickedDate > currentDate;
  };

  const updateDates = (newStartDate, newEndDate) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const dateClick = (clickedDateNumber) => {
    const clickedDate = createClickedDate(clickedDateNumber);
    const formattedClickedDate = format(clickedDate, "yyyy-MM-dd");

    if (isDisabledDate(clickedDate)) {
      return;
    }

    if (dateType === "start") {
      updateDates(formattedClickedDate, endDate);
      if (formattedClickedDate === startDate) {
        updateDates(null, endDate);
      } else {
        if (!endDate || formattedClickedDate < endDate) {
          updateDates(formattedClickedDate, endDate);
        } else {
          updateDates(endDate, formattedClickedDate);
        }
      }
    } else if (dateType === "end") {
      if (!startDate || formattedClickedDate > startDate) {
        updateDates(startDate, formattedClickedDate);
      } else if (formattedClickedDate === endDate) {
        updateDates(startDate, null);
      } else {
        updateDates(formattedClickedDate, startDate);
      }
    }
  };

  const createClickedDate = (dayToRender) => {
    return new Date(today.getFullYear(), today.getMonth(), dayToRender);
  };

  const createCal = () => {
    let calendarDate = 1;
    const calendarRows = [];

    const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const daysInMonth = lastDate.getDate();
    const firstDayOfWeek = firstDate.getDay();

    for (let week = 0; calendarDate <= daysInMonth; week++) {
      const calendarRow = [];

      for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
        if (week === 0 && dayOfWeek < firstDayOfWeek) {
          calendarRow.push(<td key={`empty-${week}-${dayOfWeek}`} />);
        } else if (calendarDate > daysInMonth) {
          calendarRow.push(<td key={`empty-${week}-${dayOfWeek}`} />);
        } else {
          const dayToRender = calendarDate;

          const nextDate = new Date(today.getFullYear(), today.getMonth(), calendarDate);
          const clickedDate = new Date(today.getFullYear(), today.getMonth(), dayToRender);

          const clickFormats = format(clickedDate, "yyyy-MM-dd");

          const isFuture = currentDate < nextDate;
          const isEndDate = endDate && isSameDate(endDate, clickFormats);
          const isStartDate = startDate && isSameDate(startDate, clickFormats);
          const isInRange = startDate && endDate && clickFormats >= startDate && clickFormats <= endDate;

          let classNames = [];
          let rangeClassed = [];

          if (isFuture) classNames.push("impossible_select");
          if (isStartDate) classNames.push("start-date");
          if (isEndDate) classNames.push("end-date");
          if (isInRange) rangeClassed.push("in-range");

          const className = classNames.join(" ");
          const rangeClass = rangeClassed.join(" ");

          calendarRow.push(
            <CalendarDayBox
              key={`day-${dayToRender}`}
              className={`${rangeClass} ${className}`}
              onClick={() => {
                dateClick(dayToRender);
                onToggle(false);
              }}
              disabled={isFuture}
            >
              <Day className={`day_select ${className}`}>{dayToRender}</Day>
            </CalendarDayBox>
          );
          calendarDate++;
        }
      }

      calendarRows.push(<tr key={`week-${week}`}>{calendarRow}</tr>);
    }

    return (
      <Table $tLayout="fixed" className="calendar-table">
        <thead className="calendar_weekly">
          <tr className="weekly_day-box">
            <TableHeader className="sunday">일</TableHeader>
            <TableHeader>월</TableHeader>
            <TableHeader>화</TableHeader>
            <TableHeader>수</TableHeader>
            <TableHeader>목</TableHeader>
            <TableHeader>금</TableHeader>
            <TableHeader className="saturday">토</TableHeader>
          </tr>
        </thead>
        <tbody>{calendarRows}</tbody>
      </Table>
    );
  };
  const MONTHS = MonthConstant.map((month, index) => {
    return { [`month${index + 1}`]: month };
  });

  const currentMonthArr = MONTHS[today.getMonth()];
  const currentMonth = currentMonthArr[`month${today.getMonth() + 1}`];

  return (
    <>
      <WhiteLayoutBox className="calendar_box">
        <Row className="move-box" $pad="16px 20px" $align="center" $jus="space-between" $width="100%">
          <Row $align="center">
            <YearMove
              className={`prev-year ${prevYear <= defaultDate ? "" : "not-found-day"}`}
              onClick={prevYearToggle}
            >
              <YearIcon />
            </YearMove>
            <MonthMove className={`prev-month `} onClick={prevMonthToggle}>
              <MonthIcon />
            </MonthMove>
          </Row>

          <TextBox key={currentMonth.id} $class={["title", "white"]}>
            {`${today.getFullYear()}년 ${currentMonth.name}`}
          </TextBox>

          <Row $align="center">
            <MonthMove
              className={`next-month ${nextMonth <= currentDate ? "" : "not-found-day"}`}
              onClick={nextMonthToggle}
            >
              <MonthIcon />
            </MonthMove>
            <YearMove
              className={`next-year ${today.getFullYear() < currentDate.getFullYear() ? "" : "not-found-day"}`}
              onClick={nextYearToggle}
            >
              <YearIcon />
            </YearMove>
          </Row>
        </Row>
        {createCal()}
      </WhiteLayoutBox>
    </>
  );
};

export default Calendar;
