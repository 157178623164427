import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import api from "interceptor/api";
import useGetData from "data/useGetData";
import { useCommonState } from "context/useContext";
import { Row } from "assets/Styles/custom/CustomLayout";

const Iframe = styled.iframe`
  width: 100% !important;
  border-radius: 12px;
`;

const GameZone = () => {
  const { id, vendor } = useParams();
  const { setLoading } = useCommonState();

  const getGameLink = () => {
    setLoading(true);

    const apiUrl = "/v1/user-game/game-launch-link";
    api
      .get(apiUrl, {
        params: {
          game_id: id,
          vendor: vendor,
        },
      })
      .then((res) => {
        setLoading(false);
        getLink.setData(res.data.content);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const getLink = useGetData({
    firstInit: true,
    api: getGameLink,
  });

  const useLink = getLink.data;

  return useLink ? (
    <Row $flex="1">
      <Iframe src={useLink?.link} />
    </Row>
  ) : null;
};

export default GameZone;
