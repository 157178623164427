/**
 * @mask
 */

// 숫자를 3자리마다 콤마를 추가하는 함수
export const formatNumberWithCommas = (value) => {
  if (!value) return value;
  const onlyNumbers = value.toString().replace(/[^\d]/g, "");
  return onlyNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const valueReplace = (value) => {
  return parseInt(value.replace(/[^\d]/g, ""), 10);
};
