import { useEffect, useState } from "react";
import { PagingData } from "constant/PagingData";

function useGetData({
  api = {},
  page = "1",
  typeData = "",
  pageSize = "32",
  typeName = "type",
  firstInit = false,

  selectData = "",
  select = "",

  multiData = [],
  multiSelect = "provider",
}) {
  let fnSearch;
  const [error, setError] = useState(false);
  const [isInitSearch, setInitSearch] = useState(firstInit);
  const [data, setData] = useState(null);
  const [pagingInfo, setPagingInfo] = useState(null);

  // 조회 확장 정보
  const pagingData = {
    page: page ? page : PagingData.defaultCurrentPage,
    countPerPage: pageSize ? pageSize : PagingData.defaultCountPerPage,
  };

  if (typeName && typeData) {
    pagingData[typeName] = typeData;
  }

  if (multiSelect && multiData.length > 0) {
    pagingData[multiSelect] = multiData;
  }

  if (select && selectData) {
    pagingData[select] = selectData;
  }

  const [extendData, setExtendData] = useState({
    pagingData,
    sortNm: "",
  });

  const onSearchFunc = (f) => {
    fnSearch = f;
  };

  const onSortClick = (lSortData) => {
    setExtendData((prevData) => ({ ...prevData, sortNm: lSortData }));
  };

  const onTypeClick = (type) => {
    setExtendData((prevData) => ({
      ...prevData,
      pagingData: { ...prevData.pagingData, [typeName]: type },
    }));
  };

  const addProvider = (provider) => {
    setExtendData((prevData) => ({
      ...prevData,
      pagingData: {
        ...prevData.pagingData,
        [multiSelect]: provider,
      },
    }));
  };

  const onPageChange = (page, isSearch = true) => {
    if (!isSearch) {
      setInitSearch(false);
    }

    setExtendData((prevData) => ({
      ...prevData,
      pagingData: { ...prevData.pagingData, page },
    }));
  };

  const morePage = (countPerPaged = "32", isSearch = true) => {
    if (!isSearch) {
      setInitSearch(false);
    }

    setExtendData((prevData) => ({
      ...prevData,
      pagingData: { ...prevData.pagingData, countPerPage: countPerPaged },
    }));
  };

  function handleChange(e, status) {
    if (status) {
      setExtendData((prev) => ({
        ...prev,
        pagingData: {
          ...prev.pagingData,
          [status.name]: e.value,
        },
      }));
    }
  }

  useEffect(() => {
    if (isInitSearch && !fnSearch) {
      if (api && typeof api === "function") {
        api();
      } else {
        console.error("errors");
      }
    } else if (isInitSearch && fnSearch) {
      fnSearch(false);
    }
    setInitSearch(firstInit);
  }, [extendData]);

  return {
    data,
    error,

    pagingInfo,
    extendData,
    morePage,

    setData,

    setError,

    setPagingInfo,
    setExtendData,

    addProvider,
    onSortClick,
    onTypeClick,
    onPageChange,
    onSearchFunc,
    onChange: handleChange,
  };
}

export default useGetData;
