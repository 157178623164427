import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";

import { useMedia } from "util/useMedia";
import { GameInfoBox } from "assets/Styles/SLayout";
import { useCommonState } from "context/useContext";
import { CutWord } from "assets/Styles/custom/CustomText";
import { Column, ContentBox, Grid, Thumbnail } from "assets/Styles/custom/CustomLayout";

import api from "interceptor/api";
import useGetData from "data/useGetData";
import MorePaging from "components/hooks/MorePaging";
import NoListLayout from "components/common/NoListLayout";
import { SectionTitle } from "components/common/useCommonStyle";

const ForYouGame = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { setLoading } = useCommonState();
  const { isMaxTablet, isMaxMiddleM } = useMedia();

  const nameMap = {
    "/recent_games": t("sectionTitle.recent_games"),
    "/new_games": t("sectionTitle.new_games"),
    "/popular_games": t("sectionTitle.popular_games"),
    "/recommended_games": t("sectionTitle.recommended_games"),
  };
  const apiUrlMap = {
    "/recent_games": "/v1/user-game/recent-bet",
    "/new_games": "/v1/game/new-game-list",
    "/popular_games": "/v1/game/popular-game",
    "/recommended_games": "/v1/game/recommended-game",
  };

  const getGameList = () => {
    setLoading(true);
    const apiUrl = apiUrlMap[pathname] || "";
    api
      .get(apiUrl, {
        params: {
          ...useGame.extendData.pagingData,
        },
      })
      .then((res) => {
        useGame.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useGame.setPagingInfo(res.data.pageHelperDto);
        }

        setLoading(false);
        useGame.setError("");
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        useGame.setData([]);
        useGame.setError(true);
      });
  };

  const useGame = useGetData({
    pageSize: "32",
    api: getGameList,
    firstInit: true,
  });

  useEffect(() => {
    getGameList();
  }, [pathname]);

  return (
    <Column $gap={isMaxMiddleM ? "20px" : "28px"}>
      <SectionTitle name={nameMap[pathname]} />

      {useGame.data?.length > 0 && (
        <>
          <Grid>
            {useGame.data.map(({ title, thumbnail, id, vendor }) => (
              <ContentBox
                as="a"
                key={vendor + id}
                $direct="column"
                $align="center"
                $backColor="var(--c-gray-700)"
                $radius={isMaxMiddleM ? "8px" : "12px"}
                $cursor="pointer"
                onClick={() => navigate(`/game_zone/${vendor}/${id}`)}
              >
                <Thumbnail
                  $width="100%"
                  src={thumbnail}
                  $height={isMaxMiddleM ? "104px" : "179px"}
                  $radius={isMaxMiddleM ? "8px 8px 0 0" : "12px 12px 0 0"}
                  alt={vendor + id}
                />
                <GameInfoBox $pad={isMaxTablet && "8px 12px"}>
                  <CutWord $maxWidth="100%">{title}</CutWord>
                </GameInfoBox>
              </ContentBox>
            ))}
          </Grid>
        </>
      )}

      {useGame?.data?.length > 0 && (
        <MorePaging num={useGame?.data?.length} totalNum={useGame?.pagingInfo?.offset} {...useGame} />
      )}

      {useGame?.data?.length === 0 && <NoListLayout />}
    </Column>
  );
};

export default ForYouGame;
