import { format, parse } from "date-fns";

/**
 * Date type or time string -> String date
 * ex ) Sat Aug 05 2023 00:00:00 GMT+0900 (한국 표준시) -> 2023. 08. 05
 * or "18:00:00" -> "18:00"
 * @param {Date|string} time
 * @returns {string}
 */
export const timeFormat = (time) => {
  if (!time) return "-";

  try {
    if (typeof time === "string" && time.includes(":")) {
      // 시간 문자열인 경우 (예: "18:00:00")
      const [hours, minutes] = time.split(":");
      return `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`;
    } else {
      // Date 객체나 다른 형식의 날짜/시간인 경우
      return format(new Date(time), "HH:mm");
    }
  } catch (error) {
    console.error("Invalid time format:", time);
    return "-";
  }
};
