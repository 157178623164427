import React from "react";
import Routers from "routes/Routers";
import GlobalStyle from "assets/Styles/GlobalStyle";
import { CommonProvider } from "context/useContext";
import { WindowProvider } from "context/useWindow";

function App() {
  return (
    <>
      <GlobalStyle />
      <CommonProvider>
        <WindowProvider>
          <Routers />
        </WindowProvider>
      </CommonProvider>
    </>
  );
}

export default App;
