import { GoogleIcon } from "assets/components/IconComponent";

export const getOauthIcon = (oauth) => {
  switch (oauth) {
    case "google":
      return (
        <GoogleIcon
          width='20'
          height='20'
        />
      );
      break;
    default:
      return;
  }
};
