import { postRequest } from "apis/postRequest";

const useLogout = () => {
  const logout = () => {
    postRequest({
      url: "/v1/user/logout",
      body: {},
    });
    localStorage.removeItem("access-token");
    localStorage.removeItem("refresh-token");
    window.location.reload();
    window.location.href = "/";
  };

  return {
    logout,
  };
};

export default useLogout;
