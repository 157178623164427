import React, { useRef, useState } from "react";

import { TextBox } from "assets/Styles/SText";
import { InputCon } from "assets/Styles/SInput";
import { P_GradientBtn } from "assets/Styles/SButton";
import { Empty, LineSection } from "assets/Styles/SLayout";
import { Between, Column, Row } from "assets/Styles/custom/CustomLayout";
import { PasswordIcon } from "assets/components/IconComponent";

import { InputForm } from "data/useForm";
import { usePopup } from "context/useWindow";
import { useCommonState } from "context/useContext";
import { focusField, getErrorClass, validateField } from "util/FormStateUtil";

import Error from "../hooks/Error";

import api from "interceptor/api";
import useFormData from "data/useFormData";
import OauthLogin from "components/sign/OauthLogin";
import { t } from "i18next";

const Login = () => {
  const [hide, setHide] = useState(false);
  const [loginErr, setLoginErr] = useState(false);

  const { closePopup } = usePopup();
  const { inputError, setInputError } = useCommonState();

  const idRef = useRef(null);
  const passwordRef = useRef(null);

  const HidePassword = () => {
    setHide(!hide);
  };

  const isLogin = () => {
    let getError = {};

    validateField(getValue.formValue, "id", t("error.input_id"), getError);
    validateField(getValue.formValue, "password", t("error.input_password"), getError);

    if (!getValue.formValue.id) {
      focusField(idRef);
    } else if (!getValue.formValue.password) {
      focusField(passwordRef);
    }

    localStorage.removeItem("access-token");
    localStorage.removeItem("refresh-token");

    const url = "/v1/auth/login-user";
    api
      .post(url, { ...getValue.formValue }, { headers: { "Content-Type": "application/json" } })
      .then((res) => {
        const { accessToken, refreshToken } = res.data.content;
        if (accessToken && refreshToken) {
          localStorage.setItem("access-token", accessToken);
          localStorage.setItem("refresh-token", refreshToken);
          window.location.reload();
        }
      })
      .catch((err) => {
        const { status, data } = err.response;

        if (err.response && status === 401) {
          setInputError((prevError) => ({
            ...prevError,
            password: t("error.invalid_id_password"),
          }));
          setLoginErr(true);
        }
        if (status === 400 && data.errors[0].msg.code === "M001") {
          setInputError((prevError) => ({
            ...prevError,
            password: t("error.blocked_member"),
          }));
          setLoginErr(true);
        }
        console.error(err);
      });
    setInputError(getError);
  };

  const getValue = useFormData(
    {
      id: "",
      password: "",
    },
    isLogin
  );

  return (
    <Column $gap="32px">
      <Empty>
        <Column $gap="12px">
          <Empty>
            <InputCon
              className={getErrorClass(inputError, "id")}
              $radius="12px"
              $pad="12px 16px"
              $backColor={"var(--c-gray-800)"}
            >
              <InputForm
                name="id"
                ref={idRef}
                placeholder={t("placeholder.id")}
                backColor={"var(--c-gray-800)"}
                errors={inputError}
                setErrors={setInputError}
                autoFocus
                {...getValue}
              />
            </InputCon>
            <Error errors={inputError} name={"id"}></Error>
          </Empty>
          <Empty>
            <InputCon
              className={!loginErr && getErrorClass(inputError, "password")}
              $radius="12px"
              $pad="12px 16px"
              $backColor={"var(--c-gray-800)"}
            >
              <Between>
                <InputForm
                  height={"20px"}
                  ref={passwordRef}
                  type={hide ? "text" : "password"}
                  name="password"
                  backColor={"var(--c-gray-800)"}
                  placeholder={t("placeholder.password")}
                  errors={inputError}
                  setErrors={setInputError}
                  {...getValue}
                />
                <PasswordIcon
                  onClick={HidePassword}
                  show={hide ? true : false}
                  width="20"
                  height="20"
                  fill="var(--c-gray-300)"
                />
              </Between>
            </InputCon>
            <Error errors={inputError} name={"password"}></Error>
          </Empty>
        </Column>
        <Column $gap="12px" $pad="20px 0 0">
          <Row $gap="6px" $align="center">
            <TextBox>{t("button.id_find")}</TextBox>
            <TextBox>·</TextBox>
            <TextBox>{t("button.password_find")}</TextBox>
          </Row>
          <P_GradientBtn $radius="12px" $pad="12px 0" onClick={isLogin}>
            <TextBox $class={["subB", "white"]}>{t("button.login")}</TextBox>
          </P_GradientBtn>
          <Row $gap="8px">
            <TextBox>{t("infoMsg.first_visit")}</TextBox>
            <TextBox as="a" href="/sign" onClick={closePopup} $class={["subB", "purple"]}>
              {t("button.sign_up")}
            </TextBox>
          </Row>
        </Column>
      </Empty>
      <Column>
        <Row $gap="12px" $align="center">
          <LineSection $width="100%" $height="1px" $borT="1px solid var(--c-gray-600)" />
          <TextBox $class={["caption", "gray400"]} $white="nowrap">
            {t("infoMsg.login_now")}
          </TextBox>
          <LineSection $width="100%" $height="1px" $borT="1px solid var(--c-gray-600)" />
        </Row>
        <Row $gap="8px" $pad="12px 0 0" $jus="center">
          <OauthLogin />
        </Row>
      </Column>
    </Column>
  );
};

export default Login;
