import React from "react";
import { t } from "i18next";

import api from "interceptor/api";
import MyMenu from "../layout/MyMenu";
import useGetData from "data/useGetData";

import { useMedia } from "util/useMedia";
import useGetEnum from "enum/useGetEnum";
import { SelectForm } from "data/useForm";
import useFormData from "data/useFormData";
import { dateTimeFormat } from "util/DateUtil";
import { useCommonState } from "context/useContext";

import { TextBox } from "assets/Styles/SText";
import { P_GradientBtn } from "assets/Styles/SButton";
import { FlexBox, MyPageLayout } from "assets/Styles/SLayout";
import { Column, Row, ScrollBox } from "assets/Styles/custom/CustomLayout";
import { TableHeader, Table, TableDeps, TableRow } from "assets/Styles/STable";

import Pagination from "components/hooks/Pagination";
import NoListLayout from "components/common/NoListLayout";
import CalendarFilter from "components/common/CalendarFilter";

const PointHistory = () => {
  const enums = useGetEnum();

  const { setLoading } = useCommonState();
  const { isMiddleD, isMaxMiddleM, isScroll } = useMedia();

  const getPointLog = () => {
    setLoading(true);

    const apiUrl = "/v1/user/point";

    api
      .get(apiUrl, {
        params: {
          ...getValue.searchValue,
          ...getPoint.extendData.pagingData,
        },
      })
      .then((res) => {
        setLoading(false);
        getPoint.setError("");
        getPoint.setData(res.data.content);
        if (res.data.pageHelperDto) {
          getPoint.setPagingInfo(res.data.pageHelperDto);
        }
      })
      .catch((err) => {
        console.error(err);
        getPoint.setError(true);
        setLoading(false);
        getPoint.setData([]);
      });
  };

  const getPoint = useGetData({
    firstInit: true,
    api: getPointLog,
    pageSize: "10",
  });

  const getValue = useFormData(
    {
      start: "",
      end: "",
      type: "",
      state: "",
    },
    getPointLog,
    getPoint
  );

  const getData = getPoint.data;

  if (!getData) return;

  return (
    <FlexBox $gap={isMiddleD && "28px"} $direct={isMiddleD ? "row" : "column"}>
      <MyMenu />

      <MyPageLayout $dis="flex" $jus="flex-end" $maxWidth="1507px" $overX="hidden">
        <Column $gap="16px" $width="100%">
          <Row $gap="16px" $width="100%" $flexWrap="wrap">
            <SelectForm
              placeholder={t("placeholder.all_type")}
              options={enums.inoutState}
              name={"state"}
              conWidth={isMaxMiddleM && "100%"}
              selectType="search"
              {...getValue}
            />
            <SelectForm
              placeholder={t("placeholder.all_history")}
              options={enums.historyType}
              name={"type"}
              conWidth={isMaxMiddleM && "100%"}
              selectType="search"
              {...getValue}
            />
            <CalendarFilter endName="end" startName="start" {...getValue} />
            <P_GradientBtn
              $pad="10px 16px"
              $radius="12px"
              $width={isMaxMiddleM ? "100%" : "fit-content"}
              onClick={() => getValue.formSearch()}
            >
              {t("button.search")}
            </P_GradientBtn>
          </Row>

          {getData?.length === 0 ? (
            <NoListLayout />
          ) : (
            <ScrollBox $pad={"20px"} $radius="12px" $backColor="var(--c-gray-700)">
              <Table className="table-box" $tLayout="fixed" $width="100%">
                <thead>
                  <tr className="table-title">
                    <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                      No
                    </TableHeader>
                    <TableHeader $width={isScroll && "136px"} $minWidth={isScroll && "136px"}>
                      {t("table.type")}
                    </TableHeader>
                    <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                      {t("table.history")}
                    </TableHeader>
                    <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                      {t("table.process_at")}
                    </TableHeader>
                    <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                      {t("table.inout_point")}
                    </TableHeader>
                    <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                      {t("table.conversion_point")}
                    </TableHeader>
                    <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                      {t("table.after_point")}
                    </TableHeader>
                  </tr>
                </thead>
                <tbody>
                  {getData &&
                    getData.map(({ no, after_point, inout, point_type, amount, process_at }, index) => {
                      let typeText = "";
                      let stateText = "";
                      let stateStyle = [];

                      switch (point_type) {
                        case "P":
                          typeText = t("state.P");
                          break;
                        case "C":
                          typeText = t("state.C");
                          break;
                        case "M":
                          typeText = t("state.M");
                          break;
                        case "L":
                          typeText = t("state.L");
                          break;
                        case "A":
                          typeText = t("state.A");
                          break;
                        case "J":
                          typeText = t("state.J");
                          break;
                        case "I":
                          typeText = t("state.I");
                          break;
                        case "F":
                          typeText = t("state.F");
                          break;
                        case "E":
                          typeText = t("state.E");
                          break;
                        default:
                          typeText = "";
                          stateStyle = [];
                      }

                      switch (inout) {
                        case "IN":
                          stateText = t("state.in");
                          stateStyle = ["subB", "blue"];
                          break;
                        case "OUT":
                          stateText = t("state.out");
                          stateStyle = ["subB", "red"];

                          break;
                        case "CHANGE":
                          stateText = t("state.change");
                          stateStyle = ["subB", "red"];
                          break;
                        default:
                          stateText = "";
                          stateStyle = [];
                      }
                      const number = getPoint.pagingInfo.offset - index;

                      return (
                        <TableRow className="table-content" key={no}>
                          <TableDeps $white={"wrap"}>{number}</TableDeps>
                          <TableDeps $white={"wrap"}>
                            <TextBox $class={stateStyle}>{stateText}</TextBox>
                          </TableDeps>
                          <TableDeps $white={"wrap"}>{typeText}</TableDeps>
                          <TableDeps $white={"wrap"}>{dateTimeFormat(process_at)}</TableDeps>

                          <TableDeps
                            $white={"wrap"}
                            $color={inout === "IN" ? "var(--c-blue)" : inout === "OUT" ? "var(--c-red)" : ""}
                          >
                            {point_type !== "P" ? <>{amount?.toLocaleString()}</> : "-"}
                          </TableDeps>
                          <TableDeps $white={"wrap"} $color={inout === "CHANGE" ? "var(--c-red)" : ""}>
                            {point_type === "P" ? amount?.toLocaleString() : "-"}
                          </TableDeps>
                          <TableDeps $white={"wrap"}>{after_point?.toLocaleString()}</TableDeps>
                        </TableRow>
                      );
                    })}
                </tbody>
              </Table>
            </ScrollBox>
          )}
          {getData?.length !== 0 && <Pagination {...getPoint} />}
        </Column>
      </MyPageLayout>
    </FlexBox>
  );
};

export default PointHistory;
