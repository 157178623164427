import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useMedia } from "util/useMedia";
import { useCommonState } from "context/useContext";

import { TextBox } from "assets/Styles/SText";
import { PrevIcon } from "assets/components/IconComponent";
import { Between, Column, RowLink } from "assets/Styles/custom/CustomLayout";
import { GrayCon, FlexBox, MyPageLayout, LineSection, Empty } from "assets/Styles/SLayout";

import api from "interceptor/api";
import MyMenu from "../layout/MyMenu";
import useGetData from "data/useGetData";
import { BorderBtn } from "assets/Styles/SButton";
import { dateTimeFormat } from "util/DateUtil";
import { useConfirm } from "context/useWindow";
import { patchRequest } from "apis/patchRequest";
import { t } from "i18next";

const NoteContent = () => {
  const { no } = useParams();
  const { openConfirm } = useConfirm();
  const { isMiddleD, isMaxMiddleM } = useMedia();
  const { setLoading } = useCommonState();

  const getCustomerInfo = () => {
    setLoading(true);

    const apiUrl = `/v1/user/messages/${no}`;

    api
      .get(apiUrl)
      .then((res) => {
        getCustomer.setData(res.data.content);
        setLoading(false);
        getCustomer.setError("");
      })
      .catch((err) => {
        console.error(err);
        getCustomer.setError(true);
        setLoading(false);
        getCustomer.setData([]);
      });
  };

  const getCustomer = useGetData({
    firstInit: true,
    api: getCustomerInfo,
  });

  const confirmFunc = () => {
    patchRequest({
      url: "/v1/user/messages/delete",
      params: {
        message_list: [no],
      },
    });
  };

  const deleteConfirm = () => {
    openConfirm({
      title: t("alert.note_delete"),
      message: t("alert.note_delete_message"),
      isRed: true,
      subBtn: t("button.cancel"),
      mainUrl: "/note",
      Func: confirmFunc,
    });
  };

  const getData = getCustomer.data;

  if (!getData) return;
  return (
    <FlexBox $gap={isMiddleD && "28px"} $direct={isMiddleD ? "row" : "column"}>
      <MyMenu />

      <MyPageLayout>
        <Column $gap="24px">
          <Between>
            <RowLink to="/note" $gap="4px">
              <PrevIcon width="16" height="16" fill="var(--c-gray-300)" />
              <TextBox $class="gray300">{t("button.return_list")}</TextBox>
            </RowLink>
            <BorderBtn $backColor="var(--c-gray-700)" $bor="1px solid var(--c-gray-600)" onClick={deleteConfirm}>
              <TextBox $class={["subB", "red"]}>{t("button.delete")}</TextBox>
            </BorderBtn>
          </Between>

          {getData.length !== 0 && (
            <GrayCon $radius="12px" $pad={isMaxMiddleM ? "20px" : "24px 28px"}>
              <Column $gap="20px" $align="left">
                <Empty>
                  <TextBox $class={[isMaxMiddleM ? "title" : "header", "white"]}>{getData.title}</TextBox>
                  <TextBox as="p" $dis="block" $pad="8px 0 0" $class={"gray300"}>
                    {dateTimeFormat(getData.create_at)}
                  </TextBox>
                </Empty>

                <LineSection $width="100%" $borB="1px solid var(--c-gray-500)" />
                <TextBox $class="white">{getData.contents}</TextBox>
              </Column>
            </GrayCon>
          )}
        </Column>
      </MyPageLayout>
    </FlexBox>
  );
};

export default NoteContent;
