import React, { useState } from "react";

import MyMenu from "./layout/MyMenu";

import useToast from "components/hooks/useToast";

import { useMedia } from "util/useMedia";
import { useCommonState } from "context/useContext";

import { TextBox } from "assets/Styles/SText";
import { Between, Column, Row, Thumbnail } from "assets/Styles/custom/CustomLayout";
import {
  BankIcon,
  CheckIcon,
  DuplicationIcon,
  GoodIcon,
  LockIcon,
  MailIcon,
  MoneyIcon,
  PhoneIcon,
  PointIcon,
  ProfileIcon,
  SignCodeIcon,
} from "assets/components/IconComponent";
import { BorderBtn, P_GradientBox } from "assets/Styles/SButton";
import { GrayCon, Empty, FlexBox, MyPageLayout, BorderBGrayCon } from "assets/Styles/SLayout";

import api from "interceptor/api";
import useGetData from "data/useGetData";
import { usePopup } from "context/useWindow";
import { getOauthIcon } from "util/useGetSwitch";

import PhonePopup from "components/popup/PhonePopup";
import EmailPopup from "components/popup/EmailPopup";
import AccountPopup from "components/popup/AccountPopup";
import PasswordPopup from "components/popup/PasswordPopup";
import WithdrawPWPopup from "components/popup/WithdrawPWPopup";
import { t } from "i18next";

const MyPage = () => {
  const { openPopup, closePopup } = usePopup();
  const { setLoading } = useCommonState();
  const { isMiddleD, isMaxMiddleM } = useMedia();
  const { Toast, toastOpen, openToast } = useToast();

  const [toastMsg, setToastMsg] = useState("");

  const getProfileInfo = () => {
    setLoading(true);

    const apiUrl = "/v1/user/profile";

    api
      .get(apiUrl)
      .then((res) => {
        getProfile.setData(res.data.content);
      })
      .catch((err) => {
        console.error(err);
        getProfile.setData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getProfile = useGetData({
    firstInit: true,
    api: getProfileInfo,
  });

  const getData = getProfile.data;

  const copyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setToastMsg(t("toast.copy_success"));
        openToast();
      })
      .catch((err) => {});
  };

  const openPassword = () => {
    openPopup({
      title: t("popup.password_change"),
      content: PasswordPopup,
      props: {
        openToast,
        closePopup,
        setToastMsg,
      },
    });
  };

  const openPhone = () => {
    openPopup({
      title: t("popup.phone_num_change"),
      content: PhonePopup,
      props: {
        openToast,
        closePopup,
        setToastMsg,
        getProfileInfo,
      },
    });
  };

  const openEmail = (text) => {
    openPopup({
      title: `${t("placeholder.email")} ${text}`,
      content: EmailPopup,
      props: {
        text,
        openToast,
        closePopup,
        setToastMsg,
        getProfileInfo,
        getData,
      },
    });
  };

  const openAccount = () => {
    openPopup({
      title: t("popup.account_change"),
      content: AccountPopup,
      props: {
        openToast,
        closePopup,
        setToastMsg,
        getProfileInfo,
      },
    });
  };

  const openWithdrawPassword = () => {
    openPopup({
      title: t("popup.withdraw_password_change"),
      content: WithdrawPWPopup,
      props: {
        openToast,
        closePopup,
        setToastMsg,
      },
    });
  };

  if (!getData) return;

  return (
    <FlexBox $gap={isMiddleD && "28px"} $direct={isMiddleD ? "row" : "column"}>
      <MyMenu />

      <MyPageLayout>
        <Column $gap="16px">
          <GrayCon $gap="8px" $pad="20px 0" $align="center">
            <TextBox $class={["white", "header"]}>{getData.nickname}</TextBox>
            <Row $gap="6px" $align="center">
              {getData.level_icon_file_uuid && (
                <Thumbnail
                  $width="20px"
                  $height="20px"
                  src={process.env.REACT_APP_IMG_URL + getData.level_icon_file_uuid}
                />
              )}
              <TextBox $class="subB">{getData.level_name}</TextBox>
            </Row>
          </GrayCon>

          <Row $gap="16px">
            <P_GradientBox $tAlign="left" $radius="12px" $pad="14px 20px">
              <Row $gap="3px" $align="center">
                <TextBox $class={["white", "subB"]}>{t("common.money")}</TextBox>
                <MoneyIcon width="20" height="20" fill="var(--c-white)" />
              </Row>
              <TextBox $class={["white", isMaxMiddleM ? "title" : "header"]} $pad="4px 0 0">
                {getData.money?.toLocaleString()}
              </TextBox>
            </P_GradientBox>

            <P_GradientBox $tAlign="left" $radius="12px" $pad="14px 20px">
              <Row $gap="3px" $align="center">
                <TextBox $class={["white", "subB"]}>{t("common.point")}</TextBox>
                <PointIcon width="20" height="20" fill="var(--c-white)" />
              </Row>
              <TextBox $class={["white", isMaxMiddleM ? "title" : "header"]} $pad="4px 0 0">
                {getData.point?.toLocaleString()}
              </TextBox>
            </P_GradientBox>
          </Row>

          <Empty>
            <BorderBGrayCon $pad="14px 20px" $radius="12px 12px 0 0">
              <TextBox $class={["white", "title"]}>{t("infoMsg.basic_info")}</TextBox>
            </BorderBGrayCon>
            <GrayCon $pad="14px 20px" $radius="0 0 12px 12px">
              <Column $gap="10px">
                <Row $gap="10px" $height="34px" $align="center">
                  <ProfileIcon fill="var(--c-gray-400)" width="20" height="20" />
                  {getData.provider_type !== "ID" ? (
                    <Row $gap="6px" $align="center">
                      {getOauthIcon(getData.provider_type)}
                      <TextBox $class="white">{t("common.social_login")}</TextBox>
                    </Row>
                  ) : (
                    <TextBox $class="white">{getData.id}</TextBox>
                  )}
                </Row>
                {getData.provider_type === "ID" && (
                  <Between $height="34px" $align="center">
                    <Row $gap="10px">
                      <LockIcon fill="var(--c-gray-400)" width="20" height="20" />
                      <TextBox $class="white">{t("common.password")}</TextBox>
                    </Row>
                    <BorderBtn onClick={openPassword} $backColor="var(--c-gray-600)">
                      <TextBox $class={["white", "subB"]}>{t("button.change")}</TextBox>
                    </BorderBtn>
                  </Between>
                )}
                <Between $height="34px" $align="center">
                  <Row $gap="10px">
                    <PhoneIcon fill="var(--c-gray-400)" width="20" height="20" />
                    <TextBox $class="white">{getData.phone_number}</TextBox>
                  </Row>
                  <BorderBtn onClick={openPhone} $backColor="var(--c-gray-600)">
                    <TextBox $class={["white", "subB"]}>{t("button.change")}</TextBox>
                  </BorderBtn>
                </Between>
                {getData.provider_type === "ID" && (
                  <Between $height="34px" $align="center">
                    <Row $gap="10px">
                      <MailIcon fill="var(--c-gray-400)" width="20" height="20" />
                      <TextBox $class="white">{t("common.email")}</TextBox>
                    </Row>
                    <BorderBtn
                      onClick={() => openEmail(getData.email ? t("button.change") : t("button.register"))}
                      $backColor="var(--c-gray-600)"
                    >
                      <TextBox $class={["white", "subB"]}>
                        {getData.email ? t("button.change") : t("button.register")}
                      </TextBox>
                    </BorderBtn>
                  </Between>
                )}
                <Between $height="34px">
                  <Row $gap="10px">
                    <GoodIcon fill="var(--c-gray-400)" width="20" height="20" />
                    <TextBox $class="white">{t("common.my_recommended_code")}</TextBox>
                  </Row>
                  <Row $gap="6px" $align="center" $cursor="pointer" onClick={() => copyText(getData.refer_code ?? "-")}>
                    <TextBox $class={["white", "subB"]}>{getData.refer_code ?? "-"}</TextBox>
                    <DuplicationIcon width="16" height="16" />
                  </Row>
                </Between>
                {getData.join_code && (
                  <>
                    <Between $height="34px">
                      <Row $gap="10px">
                        <SignCodeIcon fill="var(--c-gray-400)" width="20" height="20" />
                        <TextBox $class="white">{t("common.join_code")}</TextBox>
                      </Row>
                      <TextBox $class={["white", "subB"]}>{getData.join_code}</TextBox>
                    </Between>
                  </>
                )}
              </Column>
            </GrayCon>
          </Empty>

          <Empty>
            <BorderBGrayCon $pad="14px 20px" $radius="12px 12px 0 0">
              <TextBox $class={["white", "title"]}>{t("infoMsg.bank_info")}</TextBox>
            </BorderBGrayCon>
            <GrayCon $pad="14px 20px" $radius="0 0 12px 12px">
              <Column $gap="10px">
                <Between $align="flex-start">
                  <Row $gap="10px">
                    <BankIcon fill="var(--c-gray-400)" width="20" height="20" />
                    <Empty>
                      <TextBox $dis="block" $class="white">
                        {getData.account_bank}
                      </TextBox>
                      <TextBox $pad="6px 0" $dis="block" $class="white">
                        {getData.account_no}
                      </TextBox>
                      <TextBox $dis="block" $class="white">
                        {getData.account_name}
                      </TextBox>
                    </Empty>
                  </Row>
                  <BorderBtn onClick={openAccount} $backColor="var(--c-gray-600)">
                    <TextBox $class={["white", "subB"]}>{t("button.change")}</TextBox>
                  </BorderBtn>
                </Between>

                <Between $align="center" $height="34px">
                  <Row $gap="10px">
                    <LockIcon fill="var(--c-gray-400)" width="20" height="20" />
                    <TextBox $class="white">{t("common.withdraw_password")}</TextBox>
                  </Row>
                  <BorderBtn onClick={openWithdrawPassword} $backColor="var(--c-gray-600)">
                    <TextBox $class={["white", "subB"]}>{t("button.change")}</TextBox>
                  </BorderBtn>
                </Between>
              </Column>
            </GrayCon>
          </Empty>
        </Column>
        {toastOpen && <Toast Icon={<CheckIcon />} toastText={toastMsg} />}
      </MyPageLayout>
    </FlexBox>
  );
};

export default MyPage;
