import React, { useEffect, useState } from "react";

import { InputCon } from "assets/Styles/SInput";
import { P_GradientBtn } from "assets/Styles/SButton";
import { Column } from "assets/Styles/custom/CustomLayout";

import { patchRequest } from "apis/patchRequest";
import { useCommonState } from "context/useContext";
import { InputForm, SelectForm } from "data/useForm";
import { getErrorClass, validateField } from "util/FormStateUtil";

import useFormData from "data/useFormData";
import Error from "components/hooks/Error";
import api from "interceptor/api";
import { t } from "i18next";

const AccountPopup = ({ openToast, setToastMsg, closePopup, getProfileInfo }) => {
  const { inputError, setInputError } = useCommonState();

  const [bank, setBank] = useState([]);

  const getValue = useFormData({
    account_no: "",
    account_bank: "",
    account_name: "",
  });

  const blurValid = (name, errorMsg) => {
    const currentError = { ...inputError };
    const formValue = getValue.formValue;

    let getError = { ...currentError };

    validateField(formValue, name, errorMsg, getError);

    setInputError(getError);
  };

  const func = () => {
    closePopup();
    openToast();
    getProfileInfo();
    setToastMsg(t("toast.action_change", { action: t("common.account_info") }));
  };

  const getBankList = () => {
    api
      .get("/v1/auth/bank")
      .then((res) => {
        setBank(res.data.content);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const patchAction = () => {
    patchRequest({
      url: "/v1/user/account",
      params: {
        ...getValue.formValue,
      },
      Func: func,
    });
  };
  useEffect(() => {
    getBankList();
  }, []);

  return (
    <>
      <Column $gap="8px" $width="100%" $pad="0 0 20px">
        <SelectForm
          name="account_bank"
          selectType="search"
          placeholder={t("placeholder.bank_select")}
          conWidth="100%"
          options={bank}
          errors={inputError}
          setErrors={setInputError}
          errorMsg={t("error.bank_select")}
          pad="12px 16px"
          minWidth="100%"
          size="var(--s-sub)"
          height="44px"
          selectColor="var(--c-white)"
          errorPadT="0px"
          {...getValue}
        />
        <InputCon className={getErrorClass(inputError, "account_no")} $pad="12px 16px" $radius="12px">
          <InputForm
            name="account_no"
            placeholder={t("placeholder.account_num")}
            errors={inputError}
            setErrors={setInputError}
            onBlur={() => blurValid("account_no", t("error.account_num_required"))}
            backColor="var(--c-gray-700)"
            {...getValue}
          />
        </InputCon>
        <Error padT={"0px"} errors={inputError} name={"account_no"} />
        <InputCon className={getErrorClass(inputError, "account_name")} $pad="12px 16px" $radius="12px">
          <InputForm
            name="account_name"
            placeholder={t("placeholder.account_name")}
            errors={inputError}
            setErrors={setInputError}
            onBlur={() => blurValid("account_name", t("error.account_name_required"))}
            {...getValue}
            backColor="var(--c-gray-700)"
          />
        </InputCon>
        <Error padT={"0px"} errors={inputError} name={"account_name"} />
      </Column>
      <P_GradientBtn
        $pad="12px 0"
        $radius="12px"
        onClick={patchAction}
        disabled={
          getValue.formValue.account_bank.length === 0 ||
          getValue.formValue.account_name.length === 0 ||
          getValue.formValue.account_no.length === 0
        }
      >
        {t("button.change")}
      </P_GradientBtn>
    </>
  );
};

export default AccountPopup;
