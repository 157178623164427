import React, { useState } from "react";

import { TextBox } from "assets/Styles/SText";
import { Between, Column, Row } from "assets/Styles/custom/CustomLayout";
import { CheckIcon, MoneyIcon } from "assets/components/IconComponent";
import { ContentSwap } from "assets/Styles/SButton";
import { GrayCon, Empty, FlexBox, MyPageLayout } from "assets/Styles/SLayout";

import api from "interceptor/api";
import MyMenu from "../layout/MyMenu";
import useGetData from "data/useGetData";
import Pagination from "components/hooks/Pagination";
import NoListLayout from "components/common/NoListLayout";

import { TabsForm } from "data/useForm";
import useGetEnum from "enum/useGetEnum";
import { dateTimeFormat } from "util/DateUtil";
import { useCommonState } from "context/useContext";

import { useMedia } from "util/useMedia";
import { useConfirm } from "context/useWindow";
import useToast from "components/hooks/useToast";
import { deleteRequest } from "apis/deleteRequest";
import { t } from "i18next";

const PointConversionList = () => {
  const enums = useGetEnum();
  const { isMiddleD } = useMedia();

  const { openConfirm } = useConfirm();
  const { setLoading } = useCommonState();
  const { openToast, toastOpen, Toast } = useToast();

  const [getContent, setGetContent] = useState([]);

  const getPointList = () => {
    setLoading(true);

    const apiUrl = "/v1/point/log";

    api
      .get(apiUrl, {
        params: {
          ...getPoint.extendData.pagingData,
        },
      })
      .then((res) => {
        setLoading(false);
        getPoint.setData(res.data);
        if (res.data.userHistoryPointList) {
          setGetContent(res.data.userHistoryPointList);
        }
        if (res.data) {
          getPoint.setPagingInfo(res.data.pageHelperDto);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        getPoint.setData([]);
      });
  };

  const getPoint = useGetData({
    firstInit: true,
    pageSize: "20",
    api: getPointList,
  });
  const getData = getPoint.data;

  const pointDelete = (no) => {
    openConfirm({
      subBtn: t("button.close"),
      mainBtn: t("button.confirm"),
      message: t("button.cancel_request"),
      Func: () => {
        deleteAction(no);
      },
    });
  };

  const deleteFunc = () => {
    getPointList();
    openToast();
  };

  const deleteAction = (no) => {
    deleteRequest({
      Func: deleteFunc,
      url: "/v1/point/cancel",
      params: {
        no: no,
      },
    });
  };

  if (!getData) return;

  return (
    <FlexBox $gap={isMiddleD && "28px"} $direct={isMiddleD ? "row" : "column"}>
      <MyMenu />

      <MyPageLayout>
        <Column $gap="24px">
          <Row $pad="6px" $radius="30px" $backColor="var(--c-gray-900)">
            <ContentSwap href="/point">{t("common.point_transfer")}</ContentSwap>
            <ContentSwap className="active">{t("sectionTitle.point_transfer_history")}</ContentSwap>
          </Row>

          <GrayCon $align="center" $pad="16px 0">
            <TextBox>{t("common.point")}</TextBox>
            <Row $gap="2px" $align="center">
              <TextBox $class={["white", "header"]}>{getData?.user_point?.toLocaleString()}</TextBox>
              <MoneyIcon width="22" height="22" fill="var(--c-white)" />
            </Row>
          </GrayCon>

          <Column $gap="12px">
            <TabsForm
              pad="10px 16px"
              radius="20px"
              textClass={["subB", "gray400"]}
              activeTc={["subB", "white"]}
              activeBg={"var(--c-gray-700)"}
              backColor={"var(--c-gray-900)"}
              options={enums.moneyChangeState}
              {...getPoint}
            />
            {getContent.length !== 0 ? (
              <>
                {getContent &&
                  getContent.map(({ no, state, amount, create_at, process_at }) => {
                    let depositState = "";
                    let successState = "";
                    let stateStyle = [];

                    switch (state) {
                      case "P":
                        depositState = t("state.complete");
                        successState = t("state.process");
                        stateStyle = ["sub", "blue"];
                        break;
                      case "D":
                        depositState = t("state.waiting");
                        successState = t("state.request");
                        stateStyle = ["sub"];
                        break;
                      case "B":
                        depositState = t("state.impossible");
                        successState = t("state.process");
                        stateStyle = ["sub", "red"];
                        break;

                      default:
                        depositState = "";
                        successState = "";
                        stateStyle = [];
                    }

                    return (
                      <GrayCon key={no}>
                        <Between $align="flex-end !important">
                          <Empty>
                            <TextBox $pad="0 0 4px" $class={stateStyle}>
                              {depositState}
                            </TextBox>
                            <Row $gap="2px" $align="center">
                              <TextBox $class={"subB"}>{amount?.toLocaleString()}</TextBox>
                              <MoneyIcon width="16" height="16" fill="var(--c-gray-200)" />
                            </Row>
                          </Empty>
                          <Empty>
                            {state === "D" && (
                              <Row $width="100%" $jus="flex-end">
                                <TextBox
                                  as="button"
                                  onClick={() => pointDelete(no)}
                                  $dis="block"
                                  $pad="0 0 4px"
                                  $white="nowrap"
                                  $width="fit-content"
                                  $textDeco="underline"
                                  $class={["sub", "red"]}
                                >
                                  {t("button.call_off")}
                                </TextBox>
                              </Row>
                            )}
                            <TextBox $dis="block" $width="100%" $white="nowrap" $class={["sub", "gray300"]}>
                              {dateTimeFormat(process_at ? process_at : create_at)} &nbsp;
                              {successState}
                            </TextBox>
                          </Empty>
                        </Between>
                      </GrayCon>
                    );
                  })}
              </>
            ) : (
              <NoListLayout />
            )}
          </Column>
          {getContent.length !== 0 && <Pagination {...getPoint} />}
        </Column>
        {toastOpen && (
          <Toast
            Icon={<CheckIcon />}
            toastText={t("toast.request_cancel", {
              action: t("common.point_transfer"),
            })}
          />
        )}
      </MyPageLayout>
    </FlexBox>
  );
};

export default PointConversionList;
