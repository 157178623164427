import React from "react";
import Banner from "./main/Banner";
import HighAmountGame from "./main/HighAmountGame";
import PopularGame from "./main/PopularGame";
import RecentTable from "./main/RecentTable";
import RecommendedGame from "./main/RecommendedGame";

import { Column } from "assets/Styles/custom/CustomLayout";

import { useMedia } from "util/useMedia";
import BannerPopup from "./popup/BannerPopup";
import InspectionPopup from "./popup/InspectionPopup";

const Main = () => {
  const { isMaxMiddleM } = useMedia();

  return (
    <Column $position="relative" $gap={isMaxMiddleM ? "36px" : "64px"}>
      <BannerPopup />
      <InspectionPopup />
      <Banner />
      <HighAmountGame />
      <PopularGame />
      <RecentTable />
      <RecommendedGame />
    </Column>
  );
};

export default Main;
