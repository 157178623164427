import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useLanguageChange } from "locales/useLanguageChange";
import { EventSourcePolyfill } from "event-source-polyfill";
import { match } from "path-to-regexp";
import { isEqual } from "lodash";
// hooks
import useRoutes from "./useRoutes";
import { useMedia } from "util/useMedia";
import { useCommonState } from "context/useContext";
// component
import Aside from "components/common/Aside";
import Header from "components/common/Header";
import Loading from "components/hooks/Loading";
import Inspection from "components/inspection/Inspection";
// style
import { Article, ContentWrap, MainLayout } from "assets/Styles/componentstyle/RouteStyle";

const Routers = () => {
  useLanguageChange();
  const [inspection, setInspection] = useState(null);

  useEffect(() => {
    const sseUrl = `${process.env.REACT_APP_BASE_URL}/v1/site`;
    const sse = new EventSourcePolyfill(sseUrl);

    try {
      sse.addEventListener("update", (e) => {
        if (e?.data) {
          const newData = JSON.parse(e.data);
          if (!isEqual(newData, inspection)) {
            setInspection(newData);
          }
        }
      });

      sse.addEventListener("ping", (e) => {
        if (e?.data) {
          const pingData = JSON.parse(e.data);
        }
      });

      sse.onerror = () => {
        sse.close();
      };

      return () => {
        sse.close();
      };
    } catch (error) {
      console.error("Error initializing SSE:", error);
      sse.close();
    }
  }, [inspection]);

  if (!inspection) return;

  const isInspectionActive = (inspection) => {
    return (
      (inspection.inspec_yn === "Y" || inspection.plan_inspec_yn === "Y") &&
      inspection.plan_inspec_current_time_in_range
    );
  };

  return isInspectionActive(inspection) ? (
    <Inspection inspection={inspection} />
  ) : (
    <Router>
      <RouteContent inspection={inspection} setInspection={setInspection} />
    </Router>
  );
};

const RouteContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;

  const { routeCollection } = useRoutes();
  const { isDesktop, isMiddleM } = useMedia();
  const { isLogin, signUp, userState } = useCommonState();

  const [menuSlide, setMenuSlide] = useState(isMiddleM && !isDesktop);

  useEffect(() => {
    const handleResize = () => {
      const newMenuSlide = isMiddleM && !isDesktop;
      setMenuSlide(newMenuSlide);
    };

    handleResize(); // 초기 호출
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMiddleM, isDesktop]);

  const menuToggle = () => {
    setMenuSlide(!menuSlide);
  };

  useEffect(() => {
    const isPrivateRoute = routeCollection.some((route) => {
      if (!route.private) return false;
      const matchPath = match(route.path, { decode: decodeURIComponent });
      const result = matchPath(pathname.pathname);
      return result !== false;
    });

    if (userState === "W" && pathname !== "/" && !pathname.includes("customer")) {
      navigate("/");
    }

    if (!isLogin && isPrivateRoute) {
      navigate("/");
    }
  }, [userState, pathname]);

  return (
    <MainLayout className={menuSlide ? "slide" : ""}>
      <ContentWrap>
        <Header menuSlide={menuSlide} menuToggle={menuToggle} />
        <Aside menuSlide={menuSlide} menuToggle={menuToggle} setMenuSlide={setMenuSlide} />

        <Suspense fallback={<Loading />}>
          <Article>
            <Routes>
              {routeCollection.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    signUp === false && route.path !== "/sign_up" ? <Navigate to="/sign_up" /> : <route.component />
                  }
                />
              ))}
            </Routes>
          </Article>
        </Suspense>
      </ContentWrap>
    </MainLayout>
  );
};

export default Routers;
