import { t } from "i18next";

export const useMenu = () => [
  {
    name: t("myMenu.my_profile"),
    key: 1,
    route: ["/my.page"],
    id: "my_profile",
  },
  {
    name: t("myMenu.recommend_management"),
    key: 2,
    route: ["/my.recommend"],
    id: "recommend_management",
  },
  {
    name: t("myMenu.betting_history"),
    key: 3,
    route: ["/my.betting"],
    id: "betting_history",
  },
  {
    name: t("myMenu.deposit"),
    key: 4,
    route: ["/deposit", "/my.deposit"],
    id: "deposit",
  },
  {
    name: t("myMenu.withdraw"),
    key: 5,
    route: ["/withdraw", "/my.withdraw"],
    id: "withdraw",
  },
  {
    name: t("myMenu.point_transfer"),
    key: 6,
    route: ["/point", "/my.point"],
    id: "point_transfer",
  },
  {
    name: t("myMenu.my.point_history"),
    key: 7,
    route: ["/my.point_history"],
    id: "history",
  },
  {
    name: t("myMenu.note"),
    key: 8,
    route: ["/note"],
    id: "note",
  },
  {
    name: t("myMenu.customer"),
    key: 9,
    route: ["/customer"],
    id: "customer",
  },
  {
    name: t("myMenu.logout"),
    key: 10,
    route: null,
    id: "logout",
  },
];

export const isActiveMenuItem = (routes, pathname) => {
  if (Array.isArray(routes) && routes.length > 0) {
    return routes.some(
      (route) => pathname === route || (pathname.startsWith(route) && pathname.charAt(route.length) === "/")
    );
  }
  return false;
};
