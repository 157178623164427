import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const ToastBox = styled.div`
  position: fixed;

  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 10000;
  text-align: center;

  width: fit-content;
  max-width: 728px;

  display: flex;
  align-items: center;
  transition: ease-in-out 0.3;
  padding: 12px 16px;
  background: var(--c-pGradient);
  border-radius: 12px;
`;

const ToastText = styled.p`
  color: var(--c-white);
  font-size: var(--s-subB);
  white-space: nowrap;
  margin-left: 8px;
`;

const useToast = () => {
  const navigate = useNavigate();
  const [toastOpen, setToastOpen] = useState(false);

  const openToast = (page) => {
    setToastOpen(true);

    setTimeout(() => {
      setToastOpen(false);
      if (page) {
        navigate(page);
      }
    }, 1500);
  };

  const Toast = ({ Icon, toastText }) =>
    toastOpen && (
      <ToastBox>
        {Icon && Icon}
        <ToastText>{toastText}</ToastText>
      </ToastBox>
    );

  return {
    Toast,
    toastOpen,
    openToast,
  };
};

export default useToast;
