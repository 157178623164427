import React from "react";
import SwiperComponent from "components/hooks/SwiperComponent";

import { TextBox } from "assets/Styles/SText";
import { P_GradientBtn } from "assets/Styles/SButton";
import { CutWord } from "assets/Styles/custom/CustomText";
import { SectionTitle } from "components/common/useCommonStyle";
import { GameInfoBox, SectionBox, Slide } from "assets/Styles/SLayout";
import { Between, ContentBox, Row, Thumbnail } from "assets/Styles/custom/CustomLayout";

import { useMedia } from "util/useMedia";
import { useStateCheck } from "util/useStateCheck";

import api from "interceptor/api";
import useGetData from "data/useGetData";
import { SlideForm } from "data/useForm";
import { useCommonState } from "context/useContext";
import { t } from "i18next";
import NoListLayout from "components/common/NoListLayout";

const RecommendedGame = () => {
  const stateCheck = useStateCheck();
  const { setLoading } = useCommonState();
  const { isDesktop, isMiddleD, isTablet, isMaxTablet, isMaxMiddleM, isMiddleM } = useMedia();

  const getWidth = () => {
    if (isDesktop) return "179px";
    if (isMiddleD) return "164px";
    if (isTablet) return "155px";
    if (isMaxMiddleM) return "115px";
    return "155px";
  };

  const size = getWidth();

  const getGameList = () => {
    setLoading(true);
    const apiUrl = "/v1/game/popular-game";
    api
      .get(apiUrl, {
        params: {
          ...useGame.extendData.pagingData,
        },
      })
      .then((res) => {
        useGame.setData(res.data.content);
        setLoading(false);
        useGame.setError("");
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        useGame.setData([]);
        useGame.setError(true);
      });
  };

  const useGame = useGetData({
    pageSize: "20",
    firstInit: true,
    api: getGameList,
  });

  const slideData = () => {
    return (
      <>
        {useGame &&
          useGame?.data?.map(({ title, id, thumbnail, vendor }) => (
            <Slide key={id + vendor}>
              <ContentBox
                $direct="column"
                $align="center"
                $width={size}
                $backColor="var(--c-gray-700)"
                $radius={isMaxMiddleM ? "8px" : "12px"}
                onClick={() => stateCheck(`/game_zone/${vendor}/${id}`)}
              >
                <Thumbnail
                  src={thumbnail}
                  $width={size}
                  $height={size}
                  $radius={isMaxMiddleM ? "8px 8px 0 0" : "12px 12px 0 0"}
                  $backColor="var(--c-gray-600)"
                />
                <GameInfoBox $pad={isMaxTablet && "8px 12px"}>
                  <CutWord $maxWidth="100%">{title}</CutWord>
                  {isTablet && (
                    <P_GradientBtn $mar="10px 0 0" $pad="12px 0" $radius="999px" $align="center">
                      {t("button.now_join_game")}
                    </P_GradientBtn>
                  )}
                </GameInfoBox>
              </ContentBox>
            </Slide>
          ))}
      </>
    );
  };

  return (
    <SectionBox>
      <Between>
        <SectionTitle name={t("sectionTitle.recommended_games")} />
        {useGame?.data?.length !== 0 && (
          <Row $align="center" $gap="16px">
            <TextBox as="a" $cursor="pointer" onClick={() => stateCheck("/recommended_games")} $class="purple">
              {t("button.view_all")}
            </TextBox>
            {isMiddleM && (
              <SlideForm
                width="36px"
                radius="12px"
                height="36px"
                backColor="var(--c-gray-700)"
                prevEl="recommended-prev-button"
                nextEl="recommended-next-button"
              />
            )}
          </Row>
        )}
      </Between>

      {useGame?.data?.length > 0 ? (
        <SwiperComponent
          spaceBetween="8"
          slideData={slideData}
          useNavigation={true}
          slidesPerView={"auto"}
          prevEl=".recommended-prev-button"
          nextEl=".recommended-next-button"
        />
      ) : (
        <NoListLayout pad="65px 0" />
      )}
    </SectionBox>
  );
};

export default RecommendedGame;
