import React, { useEffect, useRef } from "react";
import { Swiper } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css/bundle";

const SwiperComponent = ({
  slideData,
  spaceBetween,
  slidesPerView = "auto",
  useAutoplay = false,
  usePagination = false,
  useNavigation = false,
  loop = false,
  swiperClass = "swiper",
  delay = 3000,
  prevEl = ".swiper-btn-prev",
  nextEl = ".swiper-btn-next",
  slidesPerGroup = 1,
  initialSlide = 0,
  rewind = false,
}) => {
  const swiperRef = useRef(null);
  const modules = [useAutoplay && Autoplay, useNavigation && Navigation, usePagination && Pagination].filter(Boolean);

  const swiperProps = {
    modules,
    spaceBetween,
    slidesPerView,
    slidesPerGroup,
    initialSlide,
    loop,
    rewind,
    navigation: useNavigation ? { prevEl, nextEl } : false,
    autoplay: useAutoplay
      ? {
          delay,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
          stopOnLastSlide: false,
        }
      : false,
    pagination: usePagination
      ? {
          clickable: true,
        }
      : false,
    className: swiperClass,
    watchOverflow: true,
    observer: true,
    observeParents: true,
    updateOnWindowResize: true,
  };

  return (
    <Swiper {...swiperProps} ref={swiperRef}>
      {slideData()}
    </Swiper>
  );
};

export default SwiperComponent;
