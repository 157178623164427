import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { RequiredText, SuccessMsg, TextBox } from "assets/Styles/SText";
import { InputCon } from "assets/Styles/SInput";
import { Empty, LineSection } from "assets/Styles/SLayout";
import { InputForm, SelectForm } from "data/useForm";
import { P_GradientBtn } from "assets/Styles/SButton";
import { PasswordIcon } from "assets/components/IconComponent";
import { Between, Column, Row } from "assets/Styles/custom/CustomLayout";

import { Timer } from "./Timer";
import { useMedia } from "util/useMedia";
import { useAlert } from "context/useWindow";
import { useCommonState } from "context/useContext";
import { getErrorClass, validateField } from "util/FormStateUtil";

import api from "interceptor/api";
import useFormData from "data/useFormData";
import Error from "components/hooks/Error";
import { t } from "i18next";
import useGetEnum from "enum/useGetEnum";
import { validationTypeEnum } from "enum/useCommonEnum";

const SignContent = styled.div`
  width: 100%;
`;

const NotRequired = styled(TextBox)`
  width: fit-content;
  padding-bottom: 8px;

  color: var(--c-white);
  font-size: var(--s-caption);
`;

const options = { OPTIONS: { label: "+82", value: "+82", key: 1 } };

const SignUp = () => {
  const enums = useGetEnum();

  const { openAlert } = useAlert();
  const { isMaxMiddleM } = useMedia();
  const { inputError, setInputError, signUp } = useCommonState();

  const [duplicateID, setDuplicateID] = useState();
  const [duplicateNick, setDuplicateNick] = useState();

  const [bank, setBank] = useState([]);

  const [timerActive, setTimerActive] = useState(false);
  const [certifyResult, setCertifyResult] = useState(false);

  const [existJoinOrReferCode, setExistJoinOrReferCode] = useState();
  const [passwordState, setPasswordState] = useState({
    password: { hide: false, type: "password" },
    password2: { hide: false, type: "password" },
    account_out_password: { hide: false, type: "password" },
  });

  const [certifyNum, setCertifyNum] = useState(false);

  const getValue = useFormData({
    id: "",
    email: "",
    nickname: "",
    password: "",
    password2: "",
    code_type: "JOIN",
    code: "",
    account_no: "",
    phone_number: "",
    account_bank: "",
    account_name: "",
    certify: "",
    account_out_password: "",
  });

  const certifyReset = () => {
    getValue.setFormValue((prevValues) => ({
      ...prevValues,
      certify: "",
    }));
    setInputError((prevErrors) => ({
      ...prevErrors,
      certify: "",
    }));
    setCertifyNum(false);
  };

  const alert = () => {
    openAlert({
      message: t("alert.auth_num_expired"),
      message2: t("alert.retry"),
      Func: () => certifyReset(),
    });
  };

  const HidePassword = (type) => {
    setPasswordState((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        hide: !prevState[type].hide,
        type: prevState[type].hide ? "password" : "text",
      },
    }));
  };

  const certifyShow = () => {
    setCertifyNum(true);
    setTimerActive(true);

    setInputError((prevErrors) => ({
      ...prevErrors,
      phone_number: "",
    }));
  };

  const getDuplicate = (type) => {
    const apiUrlMap = {
      id: "/v1/auth/id",
      nickname: "/v1/auth/nickname",
      join_code: "/v1/auth/join-code",
      refer_code: "/v1/auth/refer-code",
    };

    const paramKeyMap = {
      id: "id",
      nickname: "nickname",
      join_code: "join_code",
      refer_code: "refer_code",
    };

    let paramValue;

    if (type === "id") {
      paramValue = getValue.formValue.id;
    } else if (type === "nickname") {
      paramValue = getValue.formValue.nickname;
    } else {
      paramValue = getValue.formValue.code;
    }
    const apiUrl = apiUrlMap[type];
    const paramKey = paramKeyMap[type];
    const params = { [paramKey]: paramValue };

    api
      .get(apiUrl, { params })
      .then((res) => {
        switch (type) {
          case "id":
            setDuplicateID(res.data.isDuplicateId);
            if (res.data.isDuplicateId === true) {
              setInputError((prevErrors) => ({
                ...prevErrors,
                id: t("error.already_id"),
              }));
            }
            break;
          case "nickname":
            setDuplicateNick(res.data.isDuplicateNickname);
            if (res.data.isDuplicateNickname === true) {
              setInputError((prevErrors) => ({
                ...prevErrors,
                nickname: t("error.already_nickname"),
              }));
            }
            break;
          case "join_code":
            setExistJoinOrReferCode(res.data.isExistJoinCode);
            if (!res.data.isExistJoinCode) {
              setInputError((prevErrors) => ({
                ...prevErrors,
                code: t("error.invalid_code"),
              }));
            }
            break;
          case "refer_code":
            setExistJoinOrReferCode(res.data.isExistReferCode);
            if (!res.data.isExistReferCode) {
              setInputError((prevErrors) => ({
                ...prevErrors,
                code: t("error.invalid_code"),
              }));
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let formValued = getValue.formValue;

  const postSignUp = () => {
    const apiUrl = signUp === false ? "/v1/user/sign-up/oauth" : "/v1/auth/sign-up/direct";

    api
      .post(apiUrl, {
        ...getValue.formValue,
      })
      .then((res) => {
        if (signUp === false) {
          const { accessToken, refreshToken } = res.data.content;
          localStorage.removeItem("access-token");
          localStorage.removeItem("refresh-token");

          if (accessToken && refreshToken) {
            localStorage.setItem("access-token", accessToken);
            localStorage.setItem("refresh-token", refreshToken);
          }
          window.location.href = "/";
        } else {
          const url = "/v1/auth/login-user";

          api
            .post(url, { ...getValue.formValue }, { headers: { "Content-Type": "application/json" } })
            .then((res) => {
              const { accessToken, refreshToken } = res.data.content;
              if (accessToken && refreshToken) {
                localStorage.setItem("access-token", accessToken);
                localStorage.setItem("refresh-token", refreshToken);
              }
              window.location.reload();
              window.location.href = "/";
            })
            .catch((err) => {
              console.error(err);
            });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const blurValid = (name, errorMsg) => {
    const currentError = { ...inputError };
    const formValue = formValued;

    let getError = { ...currentError };

    validateField(formValue, name, errorMsg, getError);

    const validationMap = {
      id: () => formValue.id.length > 0 && !duplicateID && (getError.id = t("error.duplicate_check_required")),
      password2: () =>
        formValue.password2.length !== 0 &&
        formValue.password !== formValue.password2 &&
        (getError.password2 = t("error.password_mismatch")),
      account_out_password: () =>
        formValue.account_out_password.length !== 6 &&
        (getError.account_out_password = t("error.account_out_password_required")),
      phone_number: () =>
        formValue.phone_number.length !== 0 && (getError.phone_number = t("error.proceed_certification")),
      email: () =>
        !/^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/i.test(formValue.email) &&
        (getError.email = t("error.invalid_email")),
      code: () => formValue.code.length > 0 && !existJoinOrReferCode && (getError.code = t("error.code_required")),
      nickname: () =>
        formValue.nickname.length > 0 && !duplicateNick && (getError.nickname = t("error.duplicate_check_required")),
    };

    validationMap[name]?.();
    setInputError(getError);
  };

  useEffect(() => {
    if (duplicateID === false && formValued.id) {
      setDuplicateID(null);
    }
  }, [formValued.id]);

  useEffect(() => {
    if (duplicateNick === false && formValued.nickname) {
      setDuplicateNick(null);
    }
  }, [formValued.nickname]);

  useEffect(() => {
    if (existJoinOrReferCode === true && formValued.code) {
      setExistJoinOrReferCode(null);
    }
  }, [formValued.code]);

  useEffect(() => {
    if (existJoinOrReferCode === true && formValued.code) {
      setExistJoinOrReferCode(null);
    }
  }, [formValued.code]);

  const excludedFields =
    signUp === false ? ["id", "email", "code", "certify", "password", "password2"] : ["email", "code", "certify"];

  const allValuesFilled =
    Object.entries(formValued).filter(([key, value]) => !excludedFields.includes(key) && value).length ===
      Object.entries(formValued).length - excludedFields.length &&
    formValued.password === formValued.password2 &&
    formValued.account_out_password.length === 6 &&
    duplicateID === false &&
    duplicateNick === false &&
    existJoinOrReferCode === true;

  const subValuesFilled =
    Object.entries(formValued).filter(([key, value]) => !excludedFields.includes(key) && value).length ===
      Object.entries(formValued).length - excludedFields.length &&
    formValued.account_out_password.length === 6 &&
    duplicateNick === false &&
    existJoinOrReferCode === true;

  const getBankList = () => {
    api
      .get("/v1/auth/bank")
      .then((res) => {
        setBank(res.data.content);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    getBankList();
  }, []);

  const certifySuccess = () => {
    setCertifyResult(true);
  };

  return (
    <Column $gap={isMaxMiddleM ? "16px" : "28px"} $align="center">
      <Column>
        <TextBox $tAlign="center" $class={[isMaxMiddleM ? "title" : "header", "white"]}>
          {t("common.sign_up")}
        </TextBox>
        {signUp === false && <TextBox $pad="6px 0 0">{t("infoMsg.additional_info")}</TextBox>}
      </Column>

      <LineSection $width="100%" $borB="1px solid var(--c-gray-600)" />

      <Column $gap="32px" $align="center" $width="100%" $maxWidth="328px">
        {signUp !== false && (
          <>
            <SignContent $width="100%" className="id-sign-content">
              <RequiredText>{t("common.id")}</RequiredText>
              <Row $gap="8px">
                <InputCon
                  $pad="12px 16px"
                  $radius="12px"
                  className={duplicateID === false ? "" : getErrorClass(inputError, "id")}
                >
                  <InputForm
                    name="id"
                    placeholder={t("common.id")}
                    errors={inputError}
                    onBlur={() => blurValid("id", t("error.invalid_id"))}
                    setErrors={setInputError}
                    {...getValue}
                    backColor="var(--c-gray-700)"
                  />
                </InputCon>
                <P_GradientBtn
                  onClick={() => getDuplicate("id")}
                  $radius="12px"
                  $pad="12px 0px"
                  $width="71px"
                  disabled={formValued.id.length === 0}
                >
                  {t("button.duplicate_check")}
                </P_GradientBtn>
              </Row>
              {duplicateID === false && <SuccessMsg $mar="8px 0 0">{t("infoMsg.can_use_id")}</SuccessMsg>}

              {duplicateID !== false && <Error errors={inputError} name={"id"}></Error>}
            </SignContent>

            <SignContent $width="100%" className="password-sign-content">
              <RequiredText>{t("common.password")}</RequiredText>
              <InputCon className={getErrorClass(inputError, "password")} $radius="12px" $pad="12px 16px">
                <Between $gap="12px">
                  <InputForm
                    height={"20px"}
                    type={passwordState.password.type}
                    name="password"
                    backColor={"var(--c-gray-7000)"}
                    placeholder={t("placeholder.password")}
                    errors={inputError}
                    onBlur={() => blurValid("password", t("error.password_required"))}
                    setErrors={setInputError}
                    {...getValue}
                  />
                  <PasswordIcon
                    onClick={() => HidePassword("password")}
                    show={passwordState.password.hide}
                    width="20"
                    height="20"
                    fill="var(--c-gray-300)"
                  />
                </Between>
              </InputCon>
              <Error errors={inputError} name={"password"}></Error>
            </SignContent>

            <SignContent $width="100%" className="re-pass-sign-content">
              <RequiredText>{t("common.confirm_password")}</RequiredText>
              <InputCon className={getErrorClass(inputError, "password2")} $radius="12px" $pad="12px 16px">
                <Between $gap="12px">
                  <InputForm
                    height={"20px"}
                    type={passwordState.password2.type}
                    name="password2"
                    backColor={"var(--c-gray-7000)"}
                    placeholder={t("placeholder.confirm_password")}
                    errors={inputError}
                    setErrors={setInputError}
                    {...getValue}
                  />
                  <PasswordIcon
                    onClick={() => HidePassword("password2")}
                    show={passwordState.password2.hide}
                    width="20"
                    height="20"
                    fill="var(--c-gray-300)"
                  />
                </Between>
              </InputCon>
              <Error errors={inputError} name={"password2"}></Error>
            </SignContent>
          </>
        )}

        <SignContent $width="100%" className="phone-sign-content">
          <RequiredText>{t("common.phone_num")}</RequiredText>
          <SelectForm
            name="country_code"
            selectType="search"
            placeholder={t("placeholder.country_num")}
            conWidth="100%"
            options={options}
            errors={inputError}
            setErrors={setInputError}
            errorMsg={t("error.country_num_required")}
            pad="12px 16px"
            minWidth="100%"
            size="var(--s-sub)"
            height="fit-content"
            minHeight="fit-content"
            selectColor="var(--c-white)"
            {...getValue}
          />
          <Row $gap="8px" $pad="8px 0 0">
            <InputCon className={getErrorClass(inputError, "phone_number")} $pad="12px 16px" $radius="12px">
              <InputForm
                name="phone_number"
                placeholder={t("placeholder.no_hyphen_num")}
                onBlur={() => blurValid("phone_number", t("error.phone_num_required"))}
                {...getValue}
                backColor="var(--c-gray-700)"
                disabled={certifyResult}
              />
            </InputCon>
            <P_GradientBtn
              onClick={certifyShow}
              $radius="12px"
              $width="71px"
              $pad="12px 0"
              disabled={formValued.phone_number.length === 0 || certifyResult}
            >
              {t("button.certify")}
            </P_GradientBtn>
          </Row>
          <Error errors={inputError} name={"phone_number"}></Error>
          <Row $gap="8px" $pad="8px 0 0">
            <InputCon $pad="12px 16px" $radius="12px" className={getErrorClass(inputError, "certify")}>
              <Between>
                <InputForm
                  name="certify"
                  placeholder={t("placeholder.auth_num")}
                  onBlur={() => blurValid("certify", t("error.certification_required"))}
                  {...getValue}
                  backColor="var(--c-gray-700)"
                  disabled={certifyResult || !certifyNum}
                />
                {certifyNum && !certifyResult && (
                  <Timer alert={alert} reTimer={timerActive} setReTimer={setTimerActive} />
                )}
              </Between>
            </InputCon>
            <P_GradientBtn
              onClick={certifySuccess}
              $radius="12px"
              $width="71px"
              $pad="12px 0"
              disabled={formValued.certify.length === 0 || certifyResult}
            >
              {t("button.certify_complete")}
            </P_GradientBtn>
          </Row>
          <Empty $pad="8px 0 0">
            {inputError ? (
              <Error padT="0" errors={inputError} name={"certify"}></Error>
            ) : (
              <>
                {!certifyResult && certifyNum && (
                  <TextBox $class={["caption", "gray400"]}>{t("infoMsg.certify_num_sent")}</TextBox>
                )}
                {certifyResult && <SuccessMsg>{t("infoMsg.certify_complete")}</SuccessMsg>}
              </>
            )}
          </Empty>
        </SignContent>

        {signUp !== false && (
          <SignContent $width="100%" className="email-sign-content">
            <NotRequired>{t("common.email")}</NotRequired>
            <InputCon
              className={formValued.email.length === 0 ? "" : getErrorClass(inputError, "email")}
              $radius="12px"
              $pad="12px 16px"
            >
              <InputForm
                height={"20px"}
                name="email"
                backColor={"var(--c-gray-700)"}
                placeholder={t("placeholder.test_email")}
                errors={inputError}
                setErrors={setInputError}
                onBlur={formValued.email.length > 0 ? () => blurValid("email", t("error.invalid_email")) : undefined}
                {...getValue}
              />
            </InputCon>
            {formValued.email.length !== 0 && <Error errors={inputError} name={"email"}></Error>}
          </SignContent>
        )}

        <SignContent $width="100%" className="nick-sign-content">
          <RequiredText>{t("common.nickname")}</RequiredText>
          <Row $gap="8px">
            <InputCon
              $pad="12px 16px"
              $radius="12px"
              className={duplicateNick !== false && getErrorClass(inputError, "nickname")}
            >
              <InputForm
                name="nickname"
                placeholder={t("placeholder.nickname")}
                onBlur={() => blurValid("nickname", t("error.invalid_nickname"))}
                backColor="var(--c-gray-700)"
                {...getValue}
              />
            </InputCon>
            <P_GradientBtn
              $radius="12px"
              $width="71px"
              $pad="12px 0"
              onClick={() => getDuplicate("nickname")}
              disabled={formValued.nickname.length === 0}
            >
              {t("button.duplicate_check")}
            </P_GradientBtn>
          </Row>
          {duplicateNick === false && <SuccessMsg $mar="8px 0 0">{t("infoMsg.can_use_nickname")}</SuccessMsg>}
          {duplicateNick !== false && <Error errors={inputError} name={"nickname"}></Error>}
        </SignContent>

        <SignContent $width="100%" className="account-no-sign-content">
          <RequiredText>{t("common.account_info")}</RequiredText>
          <SelectForm
            name="account_bank"
            placeholder={t("placeholder.bank_select")}
            selectType="search"
            conWidth="100%"
            errors={inputError}
            setErrors={setInputError}
            errorMsg={t("error.bank_select")}
            options={bank}
            pad="12px 16px"
            minWidth="100%"
            height="fit-content"
            minHeight="fit-content"
            selectColor="var(--c-white)"
            {...getValue}
          />
          <InputCon className={getErrorClass(inputError, "account_no")} $mar="8px 0 0" $pad="12px 16px" $radius="12px">
            <InputForm
              name="account_no"
              placeholder={t("placeholder.account_num")}
              errors={inputError}
              setErrors={setInputError}
              onBlur={() => blurValid("account_no", t("error.account_num_required"))}
              backColor="var(--c-gray-700)"
              {...getValue}
            />
          </InputCon>
          <Error errors={inputError} name={"account_no"} />
          <InputCon
            className={getErrorClass(inputError, "account_name")}
            $mar="8px 0 0"
            $pad="12px 16px"
            $radius="12px"
          >
            <InputForm
              name="account_name"
              placeholder={t("placeholder.account_name")}
              errors={inputError}
              setErrors={setInputError}
              onBlur={() => blurValid("account_name", t("error.account_name_required"))}
              {...getValue}
              backColor="var(--c-gray-700)"
            />
          </InputCon>
          <Error errors={inputError} name={"account_name"} />
        </SignContent>

        <SignContent $width="100%" className="account_out_password-sign-content">
          <RequiredText>{t("common.withdraw_password")}</RequiredText>

          <InputCon className={getErrorClass(inputError, "account_out_password")} $pad="12px 16px" $radius="12px">
            <Between $gap="12px">
              <InputForm
                height={"20px"}
                validationType={validationTypeEnum.NUMBER}
                type={passwordState.account_out_password.type}
                name="account_out_password"
                errors={inputError}
                setErrors={setInputError}
                placeholder={t("placeholder.account_out_password")}
                onBlur={() => blurValid("account_out_password", t("error.account_out_password_required"))}
                backColor="var(--c-gray-700)"
                {...getValue}
              />
              <PasswordIcon
                onClick={() => HidePassword("account_out_password")}
                show={passwordState.password.hide}
                width="20"
                height="20"
                fill="var(--c-gray-300)"
              />
            </Between>
          </InputCon>

          <Error errors={inputError} name={"account_out_password"} />
        </SignContent>

        <SignContent $width="100%" className="sign-refer-code-content">
          <RequiredText>{t("common.join_or_refer_code")}</RequiredText>

          <SelectForm
            name="code_type"
            selectType="search"
            conWidth="100%"
            errors={inputError}
            setErrors={setInputError}
            errorMsg={t("error.bank_select")}
            pad="12px 16px"
            minWidth="100%"
            height="fit-content"
            minHeight="fit-content"
            selectColor="var(--c-white)"
            options={enums.joinOrReferCode}
            {...getValue}
          />
          <Row $gap="8px" $pad="8px 0 0">
            <InputCon
              $pad="12px 16px"
              $radius="12px"
              className={existJoinOrReferCode === true ? "" : getErrorClass(inputError, "code")}
            >
              <InputForm
                name="code"
                placeholder={t("placeholder.code_required")}
                onBlur={() => blurValid("code", t("error.code_required"))}
                backColor="var(--c-gray-7000)"
                {...getValue}
              />
            </InputCon>

            <P_GradientBtn
              onClick={() => getDuplicate(getValue.formValue.code_type === "JOIN" ? "join_code" : "refer_code")}
              $radius="12px"
              $width="71px"
              $pad="12px 0"
              disabled={formValued.code.length === 0}
            >
              {t("button.code_check")}
            </P_GradientBtn>
          </Row>
          {existJoinOrReferCode !== true && <Error errors={inputError} name={"code"} />}
          {existJoinOrReferCode === true && <SuccessMsg $mar="8px 0 0">{t("infoMsg.check_complete")}</SuccessMsg>}
        </SignContent>

        <P_GradientBtn
          onClick={postSignUp}
          $radius="12px"
          $pad="12px 0"
          disabled={signUp === false ? !subValuesFilled : !allValuesFilled}
        >
          {t("button.sign_up")}
        </P_GradientBtn>
      </Column>
    </Column>
  );
};

export default SignUp;
